
import {
  defineComponent,
  reactive,
  watch,
  onMounted,
  nextTick,
  toRefs,
  ref
} from 'vue'
import Sortable from 'sortablejs'
import sort from '@/assets/images/home/sort.png'
import add from '@/assets/images/home/add.png'
import sub from '@/assets/images/home/sub.png'
import noData from '@/assets/images/home/noData.png'
import { numFormat } from '@/utils/num_format'
import { Decimal } from 'decimal.js'
import {
  STATES,
  ENTRUSTPROPMAP,
  ORDER_CANCEL,
  LPOASTATE
} from '@/constant/index'

import dayjs from 'dayjs'
export default defineComponent({
  name: 'FargoTable',
  props: {
    list: { type: Array, default: () => [] },
    tHeaderList: { type: Array, default: () => [] },
    limit: { type: Number, default: 10 },
    tableLoading: { type: Boolean, default: false },
    draggableTable: { type: Boolean, default: false },
    page: { type: Number, default: 1 },
    stripe: { type: Boolean, default: true },
    height: { type: Number },
    buyHand: { type: Number, default: 20 }, // 每只股票一手的数量
    buyPrice: { type: String, default: '0' }, // 每只股票一股的价格
    isClick: { type: Boolean, default: false }
  },
  emits: [
    'change',
    'changeSelect',
    'update:list',
    'checkAll',
    'rowClick',
    'sortTable'
  ],
  setup(props, { emit }) {
    // eslint-disable-next-line
    let sortable: Sortable | null = null;
    const state = reactive({
      checkAll: false,
      dataList: props.list,
      selectedList: [],
      tHeaderList: props.tHeaderList
    })
    const cancelOrderDialog = ref(false)
    const cancelOrderText = ref('')
    const handleCommand = (
      index: number,
      data: Record<string, any>,
      btnType: string
    ) => {
      console.log(btnType)
      emit('change', { data, btnType })
    }
    // 监听全选
    watch(
      () => props.list,
      (newValue) => {
        const map = new Map()
        const arr1 = newValue?.filter((v: any) => v?.selectStatus)
        if (arr1.length) {
          arr1.forEach((item: any, index) => {
            item.index = index + 1
            item.tabIndexId = index + 'tabIndexId'
            map.set(item.tabIndexId, item)
          })
          props.list.forEach((v: any) => {
            if (v?.tabIndexId === map.get(v?.tabIndexId)?.tabIndexId) {
              v.indexSel = map.get(v?.tabIndexId)?.index
            }
          })
        }
        if (arr1.length === newValue.length) {
          state.checkAll = true
        } else {
          state.checkAll = false
        }
      },
      { deep: true }
    )
    // 计算是否有碎股
    const brokenComputed = (val: any, selectStatus: any) => {
      // 传入两个参数 一手为多少股 能被整除就没有碎股
      // eslint-disable-next-line
      if (val == 0 && selectStatus && val !== "") {
        return '<span>下单数量不能为0</span>'
      } else if (val === undefined || (val === '' && selectStatus)) {
        return '<span>下单数量不能为空</span>'
      }
      if (!props.buyHand) return
      if (val % props.buyHand !== 0 && selectStatus) {
        return '<span>碎股暂不支持</span>'
      }
    }
    // 计算购买力是否不足
    const buyFlag = (val: any) => {
      if (!val.num) return
      if (!props.buyPrice) return
      if (
        val.enableBalanceDisplay >
        new Decimal(val.num).mul(new Decimal(props.buyPrice)).toNumber()
      ) {
        return false
      } else if (val.selectStatus) {
        return true
      }
    }
    // 获取序号
    const getNumber = (index: any) => {
      return props.limit && props.page
        ? index + props.limit * (props.page - 1)
        : index
    }
    // 改变选中状态
    const changeCheckbox = (data: any) => {
      console.log((data.selectedIndex = 1))
      emit('changeSelect', data)
    }
    // 改变全选状态
    const handleCheckAllChange = (val: boolean) => {
      // eslint-disable-next-line no-unused-expressions
      props.list?.map((v: any) => {
        v.selectStatus = val
        // eslint-disable-next-line no-unused-expressions
        v.indexSel
      })
      emit('update:list', props.list)
      emit('checkAll', val)
    }
    // 拖拽
    const setSort = () => {
      if (!props.draggableTable) {
        return
      }
      const el = document.querySelectorAll(
        '.el-table__body-wrapper > table > tbody'
      )[0] as HTMLElement
      sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        onEnd: (evt) => {
          if (
            typeof evt.oldIndex !== 'undefined' &&
            typeof evt.newIndex !== 'undefined'
          ) {
            props.list.splice(
              evt.newIndex,
              0,
              props.list.splice(evt.oldIndex, 1)[0]
            )
            const newArray = props.list.slice(0)
            emit('update:list', [])
            nextTick(() => {
              emit('update:list', newArray)
            })
            emit('sortTable')
          }
        }
      })
    }
    // 隔行变色
    const tableRowClassName = (rowIndex: any) => {
      if ((rowIndex.rowIndex + 1) % 2 === 0) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
    // 显示个股市场颜色
    const getColor = (value: string) => {
      if (value === 'HK') {
        return '#5577cd'
      } else if (value === 'US') {
        return '#faa040'
      } else {
        return '#FF414E'
      }
    }
    // 显示 操作按钮
    const findShowBtn = (arr: any, data: any) => {
      return arr.filter(
        (i: any) =>
          i.type === 'show' || (i.typeReal && i.showValue === data[i.typeReal])
      )
    }

    const renderColor = (price: string) => {
      const p = Number(price.replace(',', ''))
      if (p > 0) {
        return 'up-color'
      } else if (p < 0) {
        return 'down-color'
      } else {
        return 'flat-color'
      }
    }

    const clickCancelOrderIcon = (val) => {
      cancelOrderText.value = val.cancelOrderText
      cancelOrderDialog.value = true
    }
    onMounted(() => {
      nextTick(() => {
        setSort()
      })
    })
    const tableCellClassName = ({
      row,
      column,
      rowIndex,
      columnIndex
    }: any) => {
      row.index = rowIndex
      column.index = columnIndex
    }
    const rowClick = (row: any) => {
      emit('rowClick', row)
    }
    return {
      dayjs,
      ...toRefs(state),
      ENTRUSTPROPMAP,
      STATES,
      ORDER_CANCEL,
      LPOASTATE,
      getNumber,
      handleCommand,
      changeCheckbox,
      handleCheckAllChange,
      setSort,
      tableRowClassName,
      brokenComputed,
      buyFlag,
      findShowBtn,
      sort,
      add,
      sub,
      noData,
      rowClick,
      tableCellClassName,
      numFormat,
      getColor,
      renderColor,
      cancelOrderText,
      cancelOrderDialog,
      clickCancelOrderIcon
    }
  }
})
