
import { defineComponent, ref } from 'vue'
import useRevise from '@/hooks/useRevise'

export default defineComponent({
  name: 'RevisePwd',
  props: {
    visible: { type: Boolean, default: false }
  },
  emits: ['update:visible', 'confirm'],
  setup(props, { emit }) {
    const ruleFormRef = ref()
    const { state, rules, confirm, loading, close } = useRevise(emit)
    const openReset = () => {
      state.resetPwdFlag = true
      close()
    }

    return {
      ruleFormRef,
      state,
      loading,
      close,
      confirm,
      rules,
      openReset
    }
  }
})
