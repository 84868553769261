import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dialogVisible-content" }
const _hoisted_2 = { class: "id-card label" }
const _hoisted_3 = { class: "label phone" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "btn-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_tips_dialog = _resolveComponent("tips-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      class: "reset-dialog",
      "model-value": _ctx.dialogVisible,
      title: "身份信息验证",
      width: "500px",
      onClose: _ctx.close,
      "show-close": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            style: {"--el-button-hover-bg-color":"none"},
            class: "canel",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            class: "sure",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.confirm(_ctx.ruleFormRef))),
            type: "primary",
            loading: _ctx.loading
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode(" 确认重置 ")
            ])),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_form, {
            ref: "ruleFormRef",
            model: _ctx.state,
            rules: _ctx.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { prop: "idCard" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.idCard,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.idCard) = $event)),
                    autocompleted: "off",
                    autocomplete: "new-password",
                    placeholder: "请输入您的证件号码",
                    size: "large"
                  }, {
                    prefix: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _cache[9] || (_cache[9] = _createElementVNode("span", null, "证件号", -1)),
                        _createElementVNode("i", {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.tipsIconDialog = true))
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "email" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.email) = $event)),
                    placeholder: "请输入邮箱",
                    size: "large"
                  }, {
                    prefix: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("div", { class: "label" }, "邮箱", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.phone,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.phone) = $event)),
                    placeholder: "请输入您的手机号",
                    maxlength: "11",
                    type: "text",
                    class: "phone",
                    size: "large",
                    readonly: ""
                  }, {
                    prefix: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _cache[11] || (_cache[11] = _createElementVNode("span", null, "手机号", -1)),
                        _createElementVNode("span", null, "+" + _toDisplayString(_ctx.state.areaCode), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "smsCode" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.smsCode,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.smsCode) = $event)),
                    placeholder: "请输入验证码",
                    type: "text",
                    maxlength: "6",
                    size: "large",
                    onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.state.smsCode = String(_ctx.state.smsCode).replace(/[^\d]/g, '')))
                  }, {
                    prefix: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("div", { class: "label" }, "验证码", -1)
                    ])),
                    suffix: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        loading: _ctx.sendSmsLoading,
                        class: _normalizeClass(['sms-code', _ctx.state.smsBtnStatus ? 'disable' : ''])
                      }, {
                        default: _withCtx(() => [
                          (_ctx.state.smsBtnStatus)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`${_ctx.state.countNum}s后重新获取`), 1))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.sendSmsHandle && _ctx.sendSmsHandle(...args)))
                              }, "获取验证码"))
                        ]),
                        _: 1
                      }, 8, ["loading", "class"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])
      ]),
      _: 1
    }, 8, ["model-value", "onClose"]),
    _createVNode(_component_tips_dialog, {
      tipsDialog: _ctx.state.tipsIconDialog,
      "onUpdate:tipsDialog": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.tipsIconDialog) = $event)),
      title: "证件号"
    }, {
      content: _withCtx(() => _cache[15] || (_cache[15] = [
        _createElementVNode("div", { class: "tips-content" }, [
          _createElementVNode("div", null, "企业账户：为该企业账户的公司注册证书之号码"),
          _createElementVNode("div", null, "（如：CI1234BX）"),
          _createElementVNode("div", null, "个人账户：为该账户持有人的开户证件号码"),
          _createElementVNode("div", null, "（大小写英文字母及括号符号需与证件一致，如：香港身份证号码：A123456(B)；中国居民身份证号码：123456789099887554）"),
          _createElementVNode("div", null, "联名账户：为该账户主要联系人的开户证件号码"),
          _createElementVNode("div", null, "（大小写英文字母及括号符号需与证件一致，如：香港身份证号码：A123456(B)；中国居民身份证号码：123456789099887554）")
        ], -1)
      ])),
      _: 1
    }, 8, ["tipsDialog"])
  ], 64))
}