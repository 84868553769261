import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import { useMutation } from '@vue/apollo-composable'
import { ElMessage } from 'element-plus'
import { nextTick } from 'process'
import { reactive } from 'vue'
import * as paramsWrap from '@/graphql/params-wrap'
import { DesensitizationType } from '@/utils/desensitization'

export type VerifyData = {
  verifyDialog: boolean
  verify2FADialog: boolean
  areaCode: string
  phone: string
  defaultPhone: string
  smsCode: string
  smsBtnStatus: boolean
  countNum: string
}

export default function useVerifyPwd() {
  const verifyData: VerifyData = reactive({
    verifyDialog: false,
    verify2FADialog: false,
    areaCode: '',
    phone: '',
    defaultPhone: '',
    smsCode: '',
    smsBtnStatus: false,
    countNum: ''
  })
  // 验证密码
  const { mutate: verifyPwd, onDone: verifyPwdDone, loading } = useMutation(gql.verifyTradePasswd)
  const submitCallback = (password: string) => {
    const params = {
      params: {
        clientId: storage.rcGetItem(StorageType.local, 'userInfo'),
        tradePassword: paramsWrap.wrapSign(password, {
          type: DesensitizationType.full
        }),
        authType: 3
      }
    }
    verifyPwd(params)
  }

  verifyPwdDone((res) => {
    const { verifyTradePasswd } = res.data
    if (verifyTradePasswd.ok) {
      verifyData.verifyDialog = false
      nextTick(() => {
        verifyData.verify2FADialog = true
      })
    } else {
      ElMessage({
        message: verifyTradePasswd.reason.desc,
        type: 'error',
        customClass: 'custom-error'
      })
    }
  })

  return {
    verifyData,
    submitCallback,
    loading
  }
}
