import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialogVisible-info" }
const _hoisted_2 = { class: "dialogVisible-content" }
const _hoisted_3 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock = _resolveComponent("Lock")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.editPassowrdDialog,
    width: "500px",
    "destroy-on-close": "",
    center: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_button, {
          class: "content-close",
          onClick: _ctx.close
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          loading: _ctx.loading,
          class: "content-confirm",
          onClick: _ctx.confirm,
          type: "primary"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("确认修改")
          ])),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "content-title" }, "修改密码", -1)),
          _createVNode(_component_el_form, {
            ref: "ruleFormRef",
            rules: _ctx.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { prop: "pwdOld" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.pwdOld,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pwdOld) = $event)),
                    placeholder: "请输入原密码",
                    "show-password": "",
                    maxlength: "20"
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Lock)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "pwdNew" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.pwdNew,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pwdNew) = $event)),
                    placeholder: "请输入新密码",
                    "show-password": "",
                    autocomplete: "off",
                    maxlength: "20"
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Lock)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "pwdNew2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.pwdNew2,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pwdNew2) = $event)),
                    placeholder: "请输入确认密码",
                    "show-password": "",
                    maxlength: "20"
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Lock)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["rules"]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tips" }, "密码长度为8-20位，必须包含大小写字母", -1))
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "forgot" }, "忘记交易密码？", -1))
    ]),
    _: 1
  }, 8, ["model-value"]))
}