import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "screenfull" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFullscreen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("svg", {
            class: "icon",
            "aria-hidden": "true",
            "font-size": "40px"
          }, [
            _createElementVNode("use", { "xlink:href": "#iconshiliangzhinengduixiang1" })
          ], -1)
        ])))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("svg", {
            class: "icon",
            "aria-hidden": "true",
            "font-size": "40px"
          }, [
            _createElementVNode("use", { "xlink:href": "#iconshiliangzhinengduixiang1" })
          ], -1)
        ])))
  ]))
}