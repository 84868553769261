
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TipsDialog',
  props: {
    tipsDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '温馨提示'
    },
    btnText: {
      type: String,
      default: '确定'
    },
    icon: {
      type: String,
      default: ''
    }
  },
  emits: ['update:tipsDialog', 'confirm'],
  setup (props, { emit }) {
    const close = () => {
      emit('update:tipsDialog', false)
    }
    return {
      close
    }
  }
})
