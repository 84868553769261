import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: '/trade',
    component: Layout,
    redirect: 'bulkTrading',
    meta: {
      title: 'trade',
      icon: '#icon404'
    },
    children: [
      {
        path: 'bulkTrading',
        component: () => import(/* webpackChunkName: "error-page-404" */ '@/views/trade/bulkTrading/index.vue'),
        name: 'BulkTrading',
        meta: {
          title: '买入/卖出',
          noCache: true
        }
      }
    ]
  }
]
console.log(ExampleRouter)
// export default ExampleRouter
