export enum EntrustStatus {
  ENTRUSTSTATYS_0 = '0', // '未报'
  ENTRUSTSTATYS_1 = '1', // '待报'
  ENTRUSTSTATYS_2 = '2', // '已报'
  ENTRUSTSTATYS_3 = '3', // '已报待撤'
  ENTRUSTSTATYS_4 = '4', // '部成待撤'
  ENTRUSTSTATYS_5 = '5', // '部撤'
  ENTRUSTSTATYS_6 = '6', // '已撤'
  ENTRUSTSTATYS_7 = '7', // '部成'
  ENTRUSTSTATYS_8 = '8', // '已成'
  ENTRUSTSTATYS_9 = '9', // '废单'
  ENTRUSTSTATYS_A = 'A', // '已报待改'
  ENTRUSTSTATYS_B = 'B', // '待复核[人手买方待确认]'
  ENTRUSTSTATYS_C = 'C', // '重新检查中'
  ENTRUSTSTATYS_D = 'D', // '待触发'
  ENTRUSTSTATYS_E = 'E', // '部成待改'
  ENTRUSTSTATYS_F = 'F', // '撤单(过期)'
  ENTRUSTSTATYS_G = 'G', // '部撤(过期)'
  ENTRUSTSTATYS_H = 'H ', // '待复核'
  ENTRUSTSTATYS_J = 'J', // '复核未通过'
  ENTRUSTSTATYS_N = 'N', // '修改待复核'
  ENTRUSTSTATYS_O = 'O', // '修改待复核[部成]'
  ENTRUSTSTATYS_P = 'P', // '修改待复核[未报]'
  ENTRUSTSTATYS_W = 'W ' // '待确认'
}
