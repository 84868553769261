import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "forget" }
const _hoisted_3 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock = _resolveComponent("Lock")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_reset_pwd = _resolveComponent("reset-pwd")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.verifyPwdDialog)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          class: "verify-pwd",
          width: "500px",
          "model-value": _ctx.verifyPwdDialog,
          title: "请输入交易密码",
          onClose: _ctx.close,
          "show-close": false,
          "destroy-on-close": ""
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_button, {
                style: {"--el-button-hover-bg-color":"none"},
                class: "canel",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("取消")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_el_button, {
                class: "sure",
                loading: _ctx.submitLoading,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitPassword(_ctx.ruleFormRef)))
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("确定")
                ])),
                _: 1
              }, 8, ["loading"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_form, {
                ref: "ruleFormRef",
                model: _ctx.state,
                rules: _ctx.rules,
                class: "form"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "password" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.state.password,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.password) = $event)),
                        placeholder: "请输入交易密码",
                        "show-password": "",
                        autocompleted: "off",
                        autocomplete: "new-password"
                      }, {
                        prefix: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_Lock)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", {
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetPwd && _ctx.resetPwd(...args)))
                    }, "忘记交易密码")
                  ])
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ])
          ]),
          _: 1
        }, 8, ["model-value", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.resetDialog)
      ? (_openBlock(), _createBlock(_component_reset_pwd, {
          key: 1,
          dialogVisible: _ctx.resetDialog,
          "onUpdate:dialogVisible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.resetDialog) = $event))
        }, null, 8, ["dialogVisible"]))
      : _createCommentVNode("", true)
  ]))
}