import * as crypoto from '@/utils/crypto'
import Desensitization, { DesensitizationOptions, DesensitizationType, DesensitizationPosition } from '@/utils/desensitization'

export function wrapSign (content: string, options?: DesensitizationOptions) {
  const rsaBase64 = crypoto.rsaEncrypt(content)
  const obj = {
    format: '',
    type: DesensitizationType.part,
    position: DesensitizationPosition.center,
    ...options
  }
  const desensitizationText = Desensitization(content, obj)
  return `${process.env.VUE_APP_H5_RSA_KEY}:RSA:SHA512:${desensitizationText}:${rsaBase64}`
}
