
import { defineComponent, reactive, toRefs } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'
import storage, { StorageType } from '@/utils/storage'
import * as paramsWrap from '@/graphql/params-wrap'
import { DesensitizationType } from '@/utils/desensitization'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'EditPassowrd',
  props: {
    editPassowrdDialog: { type: Boolean, default: false }
  },
  emits: ['update:editPassowrdDialog', 'confirm'],
  setup(props, { emit }) {
    const router = useRouter()
    const managerId = storage.rcGetItem(StorageType.local, 'userInfo')
    const state = reactive({
      managerId,
      pwdOld: '',
      pwdNew: '',
      pwdNew2: ''
    })
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,20}$/
    const validatePass = (rule: any, value: any, callback: any) => {
      if (state.pwdNew.length < 8) {
        ElMessage({
          message: '密码长度不足8-12位数',
          type: 'warning',
          customClass: 'custom-warning'
        })
      } else if (!reg.test(state.pwdNew)) {
        ElMessage({
          message: '密码必须包含大小写字母和数字',
          type: 'warning',
          customClass: 'custom-warning'
        })
      }
      callback()
    }
    const rules = reactive({
      pwdNew: [{ validator: validatePass, trigger: 'blur' }]
    })
    const { mutate, onDone, loading } = useMutation(gql.UpdateClientPassword)
    const close = () => {
      emit('update:editPassowrdDialog', false)
    }
    const confirm = () => {
      if (!state.pwdOld) {
        ElMessage({
          message: '原密码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!state.pwdNew) {
        ElMessage({
          message: '新密码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!state.pwdNew2) {
        ElMessage({
          message: '确认密码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (state.pwdNew.length < 8) {
        ElMessage({
          message: '密码长度不足8-12位数',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!reg.test(state.pwdNew)) {
        ElMessage({
          message: '密码必须包含大小写字母和数字',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (state.pwdNew && state.pwdNew !== state.pwdNew2) {
        ElMessage({
          message: '两次密码输入不一致',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      const params = {
        clientId: storage.rcGetItem(StorageType.local, 'userInfo'),
        tradePasswordOld: paramsWrap.wrapSign(state.pwdOld, {
          type: DesensitizationType.full
        }),
        tradePasswordNew: paramsWrap.wrapSign(state.pwdNew, {
          type: DesensitizationType.full
        })
      }
      mutate({ params })
    }

    onDone(res => {
      const { updateClientPassword } = res.data
      if (updateClientPassword.ok) {
        ElMessage({
          message: '交易密码修改成功,请重新解锁交易',
          type: 'success',
          customClass: 'custom-success'
        })
        const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
        const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
        if (immigrantMainAccout && [9, 10].includes(currentClient.clientType) && currentClient.fundAccountType === 3) {
          storage.rcRemoveItem(StorageType.session, `unlock-immigrant-${immigrantMainAccout}`)
        } else {
          storage.rcRemoveItem(StorageType.session, `unlock-${currentClient.clientId}`)
        }
        router.go(0)
        close()
      } else {
        ElMessage({
          message: updateClientPassword?.reason.desc,
          type: 'warning',
          customClass: 'custom-warning'
        })
      }
    })

    return {
      ...toRefs(state),
      loading,
      close,
      confirm,
      rules
    }
  }
})
