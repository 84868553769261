import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import signWrap from '@/apollo-client/sign'
import { ElMessage } from 'element-plus'
import router from '@/router'
import storage, { StorageType } from '@/utils/storage'
import webSocket from '@/mqtt'
import useClearUnlock from '@/hooks/useClearUnlock'

const cache = new InMemoryCache()

const BASE_URL = process.env.VUE_APP_BASE_API
const DMZ_URL = process.env.VUE_APP_DMZ_API

const httpLink = new HttpLink({
  uri: BASE_URL
})

const dmzLink = new HttpLink({
  uri: DMZ_URL
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(graphQLErrors, '=-=-=-=')
  if (graphQLErrors) {
    graphQLErrors.forEach((item: any) => {
      console.log(item)
      switch (item.err) {
        case 400:
          ElMessage.error('请求异常，请稍后重试！(code: 400)')
          break
        case 542:
          ElMessage.error('请求异常，请稍后重试！(code: 542)')
          break
        case 543:
          webSocket.close()
            .then(() => {
              ElMessage.error('长时间未操作，请重新登录！')
              storage.rcRemoveItem(StorageType.local, 'sessionId')
              useClearUnlock()
              storage.rcRemoveItem(StorageType.session, 'userInfo')
              storage.rcRemoveItem(StorageType.session, 'userData')
              router.push(`/login?redirect=${router.currentRoute.value.fullPath}`)
            })
          break
        case 544:
          ElMessage.error('请求异常，请稍后重试！(code: 544)')
          break
        case 546:
          ElMessage.error('请求异常，请稍后重试！(code: 546)')
          break
        case 547:
          ElMessage.error('请求异常，请稍后重试！(code: 547)')
          break
        case 545:
          webSocket.close()
            .then(() => {
              ElMessage.error('长时间未操作，请重新登录！')
              storage.rcRemoveItem(StorageType.local, 'sessionId')
              useClearUnlock()
              storage.rcRemoveItem(StorageType.session, 'reminder')
              storage.rcRemoveItem(StorageType.session, 'userInfo')
              storage.rcRemoveItem(StorageType.session, 'userData')
              router.push(`/login?redirect=${router.currentRoute.value.fullPath}`)
            })
          break
        default:
          ElMessage.error('请求异常，请稍后重试！')
          break
      }
    })
  }
  // 网络错误处理
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const additiveLink = new ApolloLink((operation, forward: any) => {
  operation.setContext(() => ({
    headers: {
      'content-type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/camelcase
      device_type: 'web',
      ...signWrap(operation)
    }
  }))

  return forward(operation).map((data: any) => {
    console.log(data, 'data')
    if (data.errors) {
      return data.errors
    } else {
      return data
    }
  })
})

export const baseClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const dmzClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, dmzLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})
