import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import { useMutation } from '@vue/apollo-composable'
import { ElMessage } from 'element-plus'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import * as paramsWrap from '@/graphql/params-wrap'
import { DesensitizationType } from '@/utils/desensitization'

type ReviseSate = {
  managerId: string
  pwdOld: string
  pwdNew: string
  pwdNew2: string
  resetPwdFlag: boolean
}
export default function useRevise(emit) {
  const router = useRouter()
  const state: ReviseSate = reactive({
    managerId: '',
    pwdOld: '',
    pwdNew: '',
    pwdNew2: '',
    resetPwdFlag: false
  })

  const clientId = storage.rcGetItem(StorageType.local, 'userInfo')

  const close = () => {
    state.pwdOld = ''
    state.pwdNew = ''
    state.pwdNew2 = ''
    emit('update:visible', false)
  }

  const { mutate, onDone, loading } = useMutation(gql.UpdateClientPassword)

  onDone(res => {
    const { updateClientPassword } = res.data
    if (updateClientPassword.ok) {
      // emit()
      close()
      ElMessage({
        message: '交易密码修改成功,请重新解锁交易',
        duration: 4000,
        type: 'success',
        customClass: 'custom-success'
      })
      const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
      const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
      // 目前只有投资移民主子账号公用主交易密码
      if ((immigrantMainAccout && [9, 10].includes(currentClient.clientType)) && currentClient.fundAccountType === 3) {
        storage.rcRemoveItem(StorageType.session, `unlock-immigrant-${immigrantMainAccout}`)
      } else {
        storage.rcRemoveItem(StorageType.session, `unlock-${currentClient.clientId}`)
      }
      setTimeout(() => {
        router.go(0)
      }, 4000)
    } else {
      ElMessage({
        message: updateClientPassword?.reason.desc,
        type: 'warning',
        customClass: 'custom-warning'
      })
    }
  })

  const validatePwdOld = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('原密码不能为空'))
    }
    callback()
  }

  const validatePass = (rule: any, value: any, callback: any) => {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,20}$/
    if (value === '') {
      callback(new Error('新密码不能为空'))
    } else if (value < 8) {
      callback(new Error('密码长度不足8-12位数'))
    } else if (!reg.test(state.pwdNew)) {
      callback(new Error('密码必须包含大小写字母和数字'))
    }
    callback()
  }

  const validateNewPwd2 = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('确认密码不能为空'))
    }
    if (state.pwdNew && state.pwdNew !== state.pwdNew2) {
      callback(new Error('两次密码输入不一致'))
    }
    callback()
  }

  const rules = reactive({
    pwdOld: [{ validator: validatePwdOld, trigger: 'blur' }],
    pwdNew: [{ validator: validatePass, trigger: 'blur' }],
    pwdNew2: [{ validator: validateNewPwd2, trigger: 'blur' }]
  })

  const confirm = (formEl) => {
    formEl.validate((valid) => {
      if (valid) {
        console.log('valid submit!')
        const params = {
          clientId: storage.rcGetItem(StorageType.local, 'userInfo'),
          tradePasswordOld: paramsWrap.wrapSign(state.pwdOld, {
            type: DesensitizationType.full
          }),
          tradePasswordNew: paramsWrap.wrapSign(state.pwdNew, {
            type: DesensitizationType.full
          })
        }
        mutate({ params })
      } else {
        console.log('valid error submit!')
        return false
      }
    })
  }
  return {
    state,
    rules,
    confirm,
    loading,
    close
  }
}
