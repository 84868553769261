import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "handle-button",
      style: _normalizeStyle({'top': _ctx.buttonTop+'px','backgroundColor': _ctx.theme}),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show= true))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "el-icon-setting" }, null, -1)
    ]), 4),
    _createVNode(_component_el_drawer, {
      title: "设置",
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
      size: "300px",
      "destroy-on-close": ""
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["modelValue"])
  ], 64))
}