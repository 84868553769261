import storage, { StorageType } from '@/utils/storage'

/**
 * 清除所有交易号解锁状态和投资移民账户
 */
export default function useClearUnlock () {
  const userData = storage.rcGetItem(StorageType.local, 'userData')
  const clientList = userData?.clientList || []
  for (let i = 0; i < clientList.length; i++) {
    const clientId = clientList[i].clientId
    storage.rcRemoveItem(StorageType.session, `unlock-${clientId}`)
    storage.rcRemoveItem(StorageType.session, `time-${clientId}`)
    storage.rcRemoveItem(StorageType.session, `time-immigrant-${clientId}`)
    storage.rcRemoveItem(StorageType.session, `unlock-immigrant-${clientId}`)
    storage.rcRemoveItem(StorageType.local, 'immigrantMainAccout')
  }
}
