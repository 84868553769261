import { useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import useStock, { Market } from './useStock'
import webSocket from '@/mqtt'
import * as quotType from '@/store/modules/quot/mutations-type'
import { useStore } from '@/store'

export default function useQuotPackage () {
  const { onResult } = useQuery(gql.commonData)
  const type = ref(Market.HK)
  const { queryAccData } = useStock(type)
  const store: any = useStore()

  const quotStatus = ref(false)
  onResult((res) => {
    const { quotPackagePrompt, userData, serverTime } = res.data
    store.commit(
      quotType.TIME_IS_DST,
      serverTime.timeIsDst
    )
    if (quotPackagePrompt.ok) {
      if (quotPackagePrompt.data.openTip) {
        quotStatus.value = true
        const topic = `push/transMsg/${userData.uid}`
        webSocket.subscribe([topic])
      }
    }
  })
  return {
    quotStatus,
    queryAccData
  }
}
