import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  createWebHistory
} from 'vue-router'
import Layout from '@/layout/Index.vue'

const constantFiles = require.context('./constantModules', true, /\.ts$/)
let constantModules: Array<RouteRecordRaw> = []
constantFiles.keys().forEach(key => {
  if (key === './index.ts') return
  constantModules = constantModules.concat(constantFiles(key).default)
})

const asyncFiles = require.context('./permissionModules', true, /\.ts$/)
let permissionModules: Array<RouteRecordRaw> = []
asyncFiles.keys().forEach(key => {
  if (key === './index.ts') return
  permissionModules = permissionModules.concat(asyncFiles(key).default)
})

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ '@/views/redirect/Index.vue'
          )
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/trade/bulkTrading'
  },
  {
    path: '/trade',
    component: Layout,
    redirect: 'bulkTrading',
    meta: {
      title: 'WEB交易',
      icon: '',
      iconName: 'lpoa'
    },
    children: [
      {
        path: 'bulkTrading',
        component: () =>
          import(
            /* webpackChunkName: "bulk-trading" */ '@/views/trade/bulkTrading/index.vue'
          ),
        name: 'BulkTrading',
        meta: {
          title: '买入/卖出',
          noCache: true,
          affix: true
        }
      },
      {
        path: 'checkDetail',
        component: () =>
          import(
            /* webpackChunkName: "check-detail" */ '@/views/trade/realTimeData/checkDetail/index.vue'
          ),
        name: 'CheckDetail',
        meta: {
          title: '今日订单',
          noCache: true,
          hidden: true
        }
      },
      {
        path: 'statement',
        component: () =>
          import(
            /* webpackChunkName: "check-detail" */ '@/views/trade/statement/index.vue'
          ),
        name: 'Statement',
        meta: {
          title: '我的结单',
          noCache: true
        }
      }
    ]
  },
  // 货币兑换Currency conversion
  {
    path: '/currency',
    component: Layout,
    redirect: 'conversion',
    meta: {
      title: '货币兑换',
      icon: '',
      iconName: 'currency'
    },
    children: [
      {
        path: 'conversion',
        component: () =>
          import(
            /* webpackChunkName: "conversion" */ '@/views/currency/index.vue'
          ),
        name: 'Conversion',
        meta: {
          title: '兑入/兑出',
          noCache: true
        }
      },
      {
        path: 'record',
        component: () =>
          import(
            /* webpackChunkName: "record" */ '@/views/currency/record.vue'
          ),
        name: 'Record',
        meta: {
          title: '兑换记录',
          noCache: true
        }
      }
    ]
  },
  ...constantModules,
  {
    path: '/:catchAll(.*)',
    component: Layout,
    redirect: '/trade/bulkTrading'
  }
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  // 设置权限使用的路由
  ...permissionModules
]
const r = process.env.NODE_ENV === 'dev' ? createWebHashHistory() : createWebHistory()
const router = createRouter({
  history: r,
  routes: constantRoutes
})

export function resetRouter () {
  const newRouter = router
  ;(router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
