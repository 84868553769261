import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/toast/voice.svg'
import _imports_1 from '@/assets/images/toast/close.svg'


const _hoisted_1 = { class: "notice-content" }
const _hoisted_2 = { style: {"fontWeight":"500"} }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "view-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showNotice)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["top-notice", _ctx.showMarquee ? 'show' : 'hide'])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              src: _imports_0,
              class: "voice",
              alt: ""
            }, null, -1)),
            _createVNode(_component_el_carousel, {
              height: "50px",
              direction: "vertical",
              autoplay: "",
              "indicator-position": "none",
              onChange: _ctx.changeNotice
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notices, (item) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, {
                    key: item.id
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1),
                        _cache[3] || (_cache[3] = _createTextVNode("    ")),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(item.content), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["onChange"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openDialog && _ctx.openDialog(...args)), ["stop"]))
              }, "查看详情"),
              _createElementVNode("img", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeMarquee && _ctx.closeMarquee(...args)), ["stop"])),
                src: _imports_1,
                alt: ""
              })
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      notice: _ctx.currentNotice,
      dialogVisible: _ctx.dialogVisible,
      "onUpdate:dialogVisible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      icon: "",
      onDialogClose: _ctx.dialogClose
    }, null, 8, ["notice", "dialogVisible", "onDialogClose"])
  ], 64))
}