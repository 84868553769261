import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "tips-dialog",
    "model-value": _ctx.tipsDialog,
    width: "500px",
    onClose: _ctx.close,
    "show-close": false,
    "destroy-on-close": ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(_ctx.icon)
            }, null, 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        class: "btn",
        onClick: _ctx.close
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["model-value", "onClose"]))
}