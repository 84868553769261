import storage, { StorageType } from '@/utils/storage'
import { computed, onMounted, reactive } from 'vue'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import Desensitization, { DesensitizationFormat } from '@/utils/desensitization'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

type RestPwd = {
  idCard: string
  smsCode: string
  email: string
  phone: string
  defaultPhone: string
  areaCode: string
  countFlag: false
  loading: false
  countNum: number
  codeStr: string
  clientId: string
  smsBtnStatus: boolean
  tipsIconDialog: boolean
}

export default function useRestPwd(emit: any) {
  const { phone } = storage.rcGetItem(StorageType.local, 'userData')
  const state: RestPwd = reactive({
    idCard: '',
    phone: '',
    defaultPhone: phone,
    smsCode: '',
    areaCode: '86',
    countFlag: false,
    loading: false,
    countNum: 60,
    codeStr: '',
    email: '',
    clientId: storage.rcGetItem(StorageType.local, 'userInfo'),
    smsBtnStatus: false,
    tipsIconDialog: false
  })

  const router = useRouter()

  let validateIdCallback
  let validateEmailCallback

  const { mutate: checkId, onDone: checkIdCardDone } = useMutation(
    gql.checkIdCard
  )
  const { mutate: checkEmail, onDone: checkEmailDone } = useMutation(
    gql.checkUserInfo
  )
  const {
    mutate: vaildSms,
    onDone: vaildSmsDone,
    loading: smsLoading
  } = useMutation(gql.getSmsVaild)
  const {
    mutate: sendPhoneSms,
    onDone: sendSmsDone,
    loading: sendSmsLoading
  } = useMutation(gql.sendPhoneSms)
  const {
    mutate: resetPwdConfirm,
    onDone: resetPwdDone,
    loading: submitLoading
  } = useMutation(gql.resetTradePassword)

  // 证件号
  const validateIdCard = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('请输入证件号！'))
    } else {
      const params = {
        filter: {
          clientId: storage.rcGetItem(StorageType.local, 'userInfo'),
          idCard: value
        }
      }
      checkId(params)
      validateIdCallback = callback
    }
  }

  checkIdCardDone(res => {
    const { checkIdCard } = res?.data
    if (checkIdCard.ok) {
      if (checkIdCard.data.resBool) {
        validateIdCallback()
      } else {
        validateIdCallback(new Error('输入证件号错误！'))
      }
    } else {
      validateIdCallback(new Error(checkIdCard.reason.desc))
    }
  })

  // 开户邮箱
  const validateEmail = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('请输入开户邮箱！'))
    } else {
      const params = {
        filter: {
          email: value
        }
      }
      checkEmail(params)
      validateEmailCallback = callback
    }
  }

  checkEmailDone(res => {
    const { checkUserInfo } = res?.data
    if (checkUserInfo.ok) {
      if (checkUserInfo.data.resBool) {
        validateEmailCallback()
      } else {
        validateEmailCallback(new Error('输入开户邮箱错误！'))
      }
    } else {
      validateEmailCallback(new Error(checkUserInfo.reason.desc))
    }
  })

  // 验证码
  const validateSms = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('请输入验证码！'))
    } else {
      callback()
    }
  }

  const sendSmsHandle = () => {
    const params = {
      param: {
        areaCode: state.areaCode,
        phone: state.defaultPhone,
        optType: 'reset_trade_password'
      }
    }
    state.smsBtnStatus = true
    sendPhoneSms(params)
  }

  sendSmsDone(res => {
    const { sendPhoneSms } = res?.data
    if (sendPhoneSms.ok && sendPhoneSms?.data?.resBool) {
      const timer = setInterval(() => {
        if (state.countNum) {
          state.countNum--
        } else {
          clearInterval(timer)
          state.smsBtnStatus = false
          state.countNum = 60
        }
      }, 1000)
    } else {
      state.smsBtnStatus = false
      ElMessage.error(sendPhoneSms.reason.desc)
    }
  })

  const rules = reactive({
    idCard: [{ validator: validateIdCard, trigger: 'blur' }],
    email: [{ validator: validateEmail, trigger: 'blur' }],
    smsCode: [{ validator: validateSms, trigger: 'blur' }]
  })

  const confirm = formEl => {
    formEl.validate(valid => {
      if (valid) {
        console.log('submit!')
        const params = {
          param: {
            areaCode: state.areaCode,
            phone: state.defaultPhone,
            msgCode: state.smsCode,
            fundAccount: state.clientId,
            authType: 1000
          }
        }
        vaildSms(params)
      } else {
        console.log('error submit!')
        return false
      }
    })
  }

  vaildSmsDone(res => {
    const { getSmsVaild } = res?.data
    if (getSmsVaild.ok) {
      if (getSmsVaild?.data?.resBool) {
        const params = {
          param: {
            conditions: [
              { key: 'id_card', value: state.idCard },
              { key: 'email', value: state.email },
              { key: 'phone', value: `${state.areaCode}${state.defaultPhone}` }
            ],
            fundAccount: state.clientId
          }
        }
        resetPwdConfirm(params)
      } else {
        ElMessage.error('验证码错误')
      }
    } else {
      ElMessage.error(getSmsVaild.reason.desc)
    }
  })

  const close = () => {
    state.idCard = ''
    state.phone = ''
    state.areaCode = ''
    state.smsCode = ''
    state.email = ''
    state.smsBtnStatus = false
    emit('update:dialogVisible', false)
  }

  resetPwdDone(res => {
    const { resetTradePassword } = res?.data
    if (resetTradePassword.ok) {
      if (resetTradePassword.data?.resBool) {
        close()
        ElMessage({
          type: 'success',
          duration: 4000,
          customClass: 'success-tips',
          dangerouslyUseHTMLString: true,
          message:
            '<div class="tips"><p>重置成功！</p><p>尊敬的客户，重置的交易密码已发送至您的邮箱，请注意查收。</p></div>'
        })
        const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
        const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
        // 目前只有投资移民主子账号公用主交易密码
        if (immigrantMainAccout && [9, 10].includes(currentClient.clientType) && currentClient.fundAccountType === 3) {
          storage.rcRemoveItem(StorageType.session, `unlock-immigrant-${currentClient.clientId}`)
        } else {
          storage.rcRemoveItem(StorageType.session, `unlock-${currentClient.clientId}`)
        }

        setTimeout(() => {
          router.go(0)
        }, 4000)
      }
    } else {
      ElMessage.error(resetTradePassword.reason.desc)
    }
  })

  onMounted(() => {
    const { areaCode, phone } = storage.rcGetItem(StorageType.local, 'userData')
    state.areaCode = areaCode
    state.phone = Desensitization(phone, {
      format: DesensitizationFormat.telPhone
    })
  })

  const fetchLoading = computed(() => smsLoading.value || submitLoading.value)

  return {
    state,
    rules,
    sendSmsHandle,
    confirm,
    sendSmsLoading,
    loading: fetchLoading
  }
}
