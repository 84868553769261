export interface CommonState {
  moneyType: string
  exchangeRate: number
  // userInfo: {
  //   username: string
  // }
}

export const state: CommonState = {
  moneyType: 'HK',
  exchangeRate: 7.79
  // userInfo: {
  //   username: 'username'
  // }
}
