import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'is-active': _ctx.isActive}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleClick && _ctx.toggleClick(...args)))
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("svg", {
      class: "icon",
      "aria-hidden": "true",
      "font-size": "20px"
    }, [
      _createElementVNode("use", { "xlink:href": "#iconhamburger" })
    ], -1)
  ]), 2))
}