import { useLazyQuery, useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { reactive, Ref, ref } from 'vue'
import _ from 'lodash'
import { STOCKQUOTE } from '@/define'
import { Decimal } from 'decimal.js'
import {
  transformQuoteField,
  transformSocketFieldUsAfterOrBefore
} from '@/utils/formatStock'
import webSocket from '@/mqtt'
import { numFormat } from '@/utils/num_format'
import storage, { StorageType } from '@/utils/storage'

export enum Market {
  HK = 'HK',
  US = 'US',
  SZ = 'SZ',
  SH = 'SH',
  A = 'A',
  TW = 'TW'
}

export default function useStock(marketType: Ref<string>) {
  const p = {
    stockPage: { exchangeType: marketType.value },
    fundAcc: {
      exchangeType: marketType.value,
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
    }
  }
  const quotOptions = ref({
    enabled: false
  })
  const quotParams = ref({})
  const {
    load: queryAccData,
    onResult,
    refetch,
    loading
  } = useLazyQuery(gql.homeAccData, p)
  const {
    onResult: quotAll,
    loading: quotLoading,
    refetch: qutoRefetch
  } = useQuery(gql.quotAll, quotParams, quotOptions)
  // const { load, onResult: quotAll, loading: quotLoading } = useLazyQuery(gql.quotAll)

  const mqttAssetIds = ref([] as string[])

  const accList: any = reactive({
    tableDataList: [], // 右侧表格数据
    nowSubscribeArr: [],
    oldSubscribeArr: [],
    listRight: {},
    totalMarketValue: '' // 个股总市值
  })

  const isDelayQuot = ref(false)

  // 请求持仓行情
  const fetchQuot = (assetIds: string[]) => {
    const aMarketType: string[] = [Market.SH, Market.SZ, Market.A]
    quotParams.value = {
      filterHk: {
        assetIds: marketType.value === Market.HK ? assetIds : [],
        fields: STOCKQUOTE.detailHk
      },
      filterUs: {
        assetIds: marketType.value === Market.US ? assetIds : [],
        fields: STOCKQUOTE.detailUs
      },
      filterA: {
        assetIds: aMarketType.includes(marketType.value) ? assetIds : [],
        fields: STOCKQUOTE.detailA
      }
    }
    if (accList.tableDataList.length) {
      quotOptions.value.enabled = true
      if (quotOptions.value.enabled) {
        qutoRefetch(quotParams.value)
      }
      // load(gql.quotAll, params)
    }
  }

  // 处理持仓列表数据
  const pullData = (data: any, code?: string, price?: string) => {
    const map = new Map()
    data.forEach((v: any) => {
      if (marketType.value === Market.US) {
        if (v.afBfStatus === '0') {
          const beforeData = transformSocketFieldUsAfterOrBefore(
            v.fieldUsBefore
          )
          v.priceX = beforeData.price
        } else if (v.afBfStatus === '1') {
          const afterData = transformSocketFieldUsAfterOrBefore(v.fieldUsAfter)
          v.priceX = afterData.price
        } else {
          v.priceX = v.price
        }
      } else {
        v.priceX = v.price
      }
      map.set(v.assetId.split('.')[0], v)
    })
    accList.tableDataList.forEach((v: any) => {
      v.enableAmount = numFormat(v.enableAmount, 0)
      v.currentAmountN = numFormat(v.currentAmount, 0)
      if (code) {
        if (v.stockCode === code) {
          v.currentPriceDisplay = price
        }
      } else {
        v.currentPriceDisplay = map.has(v.stockCode)
          ? map.get(v.stockCode).priceX === undefined
            ? map.get(v.stockCode).price
            : map.get(v.stockCode).priceX
          : '--'
      }
      v.profit =
        v.costPrice === 0
          ? '--'
          : v.currentPriceDisplay !== '- -'
          ? (Number(v.currentPriceDisplay ? v.currentPriceDisplay : '0.00') -
              v.costPrice) *
            v.currentAmount
          : '- -'
      v.profitPercent =
        v.costPrice === 0
          ? '--'
          : isNaN(
              parseFloat(
                new Decimal(v.profit)
                  .div(new Decimal(v.costPrice).mul(v.currentAmount))
                  .mul(100)
                  .toFixed(2, Decimal.ROUND_DOWN)
              )
            )
          ? '--'
          : parseFloat(
              new Decimal(v.profit)
                .div(new Decimal(v.costPrice).mul(v.currentAmount))
                .mul(100)
                .toFixed(2, Decimal.ROUND_DOWN)
            ) + '%'
      v.marketValueN = Number(v.currentPriceDisplay) * Number(v.currentAmount)
      v.marketValueF = numFormat(
        Number(v.currentPriceDisplay) * Number(v.currentAmount),
        2
      )
    })
    const arr = accList.tableDataList.map((v: any) => v.marketValueN)
    if (arr.length > 0) {
      accList.totalMarketValue =
        arr.reduce((a: any, b: any) => Number(a) + Number(b)) + '' // 计算港股美股总市值
    } else {
      accList.totalMarketValue = '0.00'
    }
  }

  const openMqtt = () => {
    if (isDelayQuot.value) {
      if (marketType.value === Market.HK) {
        accList.tableDataList.forEach(
          (v: { exchangeType: string, stockCode: string }) => {
            mqttAssetIds.value.push(
              `DELAY.QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.2`
            )
            mqttAssetIds.value.push(
              `DELAY.QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.3`
            )
          }
        )
        webSocket.subscribe(mqttAssetIds.value)
      } else if (marketType.value === Market.US) {
        accList.tableDataList.forEach(
          (v: { exchangeType: string, stockCode: string }) => {
            mqttAssetIds.value.push(
              `DELAY.QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.2`
            )
            mqttAssetIds.value.push(
              `DELAY.QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.21`
            )
          }
        )
        webSocket.subscribe(mqttAssetIds.value)
      } else {
        accList.tableDataList.forEach(
          (v: { exchangeType: string, stockCode: string }) => {
            mqttAssetIds.value.push(
              `DELAY.QUOT.ML.${v.stockCode}.${v.exchangeType}.2`
            )
            mqttAssetIds.value.push(
              `DELAY.QUOT.ML.${v.stockCode}.${v.exchangeType}.3`
            )
          }
        )
        webSocket.subscribe(mqttAssetIds.value)
      }
    } else {
      if (marketType.value === Market.HK) {
        accList.tableDataList.forEach(
          (v: { exchangeType: string, stockCode: string }) => {
            mqttAssetIds.value.push(
              `QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.2`
            )
            mqttAssetIds.value.push(
              `QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.3`
            )
          }
        )
        webSocket.subscribe(mqttAssetIds.value)
      } else if (marketType.value === Market.US) {
        accList.tableDataList.forEach(
          (v: { exchangeType: string, stockCode: string }) => {
            mqttAssetIds.value.push(
              `QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.2`
            )
            mqttAssetIds.value.push(
              `QUOT.${v.exchangeType}.${v.stockCode}.${v.exchangeType}.21`
            )
          }
        )
        webSocket.subscribe(mqttAssetIds.value)
      } else {
        accList.tableDataList.forEach(
          (v: { exchangeType: string, stockCode: string }) => {
            mqttAssetIds.value.push(
              `QUOT.ML.${v.stockCode}.${v.exchangeType}.2`
            )
            mqttAssetIds.value.push(
              `QUOT.ML.${v.stockCode}.${v.exchangeType}.3`
            )
          }
        )
        webSocket.subscribe(mqttAssetIds.value)
      }
    }
  }

  onResult((res) => {
    if (!res.loading) {
      const { stockPosPageList, fundAccMarketList, userData } = res?.data
      if (stockPosPageList.ok) {
        const rows = stockPosPageList?.data?.rows || []
        const assetIds = rows.map(
          (v: { stockCode: string, exchangeType: string }) =>
            v.stockCode + '.' + v.exchangeType
        )
        const marketValue = rows.map((v) => v.marketValue)
        if (marketValue.length) {
          accList.totalMarketValue = rows
            .map((v) => v.marketValue)
            ?.reduce((pre, cur) => pre + cur)
        } else {
          accList.totalMarketValue = 0
        }
        accList.tableDataList = _.cloneDeep(rows)
        isDelayQuot.value = userData.isDelayQuot
        if (mqttAssetIds.value.length) {
          webSocket.unsubscribe(mqttAssetIds.value)
          mqttAssetIds.value = []
        }
        // fetchQuot(assetIds)
      }
      if (fundAccMarketList.ok) {
        accList.listRight = fundAccMarketList.data[0]
      }
    }
  })

  // 查询持仓列表行情
  quotAll((res) => {
    if (!res.data) return
    const { quotHK, quotUS, quotA } = res.data
    const type = {
      [Market.HK]: quotHK,
      [Market.US]: quotUS,
      [Market.A]: quotA,
      [Market.SZ]: quotA,
      [Market.SH]: quotA
    }

    const stockType = {
      [Market.HK]: STOCKQUOTE.detailHk,
      [Market.US]: STOCKQUOTE.detailUs,
      [Market.A]: STOCKQUOTE.detailA,
      [Market.SZ]: STOCKQUOTE.detailA,
      [Market.SH]: STOCKQUOTE.detailA
    }
    let data = []
    if (type[marketType.value].code === 0) {
      data = transformQuoteField(
        type[marketType.value]?.result?.data,
        stockType[marketType.value]
      )
    }

    // openMqtt()
    // pullData(data)
  })
  return {
    queryAccData,
    accList,
    refetch,
    loading: accList.tableDataList ? loading && quotLoading : loading,
    pullData,
    isDelayQuot,
    mqttAssetIds
  }
}
