
import { ElMessage } from 'element-plus'
import _ from 'lodash'
import { computed, defineComponent, onUnmounted, reactive, ref } from 'vue'

export default defineComponent({
  name: 'Dialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    notice: {
      type: Object,
      default: () => ({})
    },
    icon: {
      type: Boolean,
      default: false
    },
    hasCancel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['dialogClose', 'dialogConfirm'],
  setup(props, { emit }) {
    const visible = computed(() => props.dialogVisible)
    const subContent = ref()
    const isBottom = ref(false)
    const state = reactive({
      scrollTop: 0,
      clientHeight: 0,
      scrollHeight: 0
    })
    const handleScroll = () => {
      state.scrollTop = subContent.value?.scrollTop
      state.clientHeight = subContent.value?.clientHeight
      state.scrollHeight = subContent.value?.scrollHeight
      // console.log(state, 'scrolltop')
      if (
        state.scrollTop !== 0 &&
        state.scrollHeight - state.clientHeight <= state.scrollTop + 500
      ) {
        isBottom.value = true
      }
    }
    window.addEventListener('scroll', _.debounce(handleScroll, 50), true)
    onUnmounted(() => {
      window.removeEventListener('scroll', _.debounce(handleScroll, 50), true)
    })
    const handleClose = () => {
      // https://zen.fwdev.top/story-view-775.html
      // if (!isBottom.value) {
      //   ElMessage({
      //     message: '请先阅读全部内容',
      //     type: 'warning',
      //     customClass: 'custom-warning'
      //   })
      //   return
      // }
      emit('dialogClose')
    }
    const handleConfirm = () => {
      // https://zen.fwdev.top/story-view-775.html
      if (!isBottom.value) {
        ElMessage({
          message: '请先阅读全部内容',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      emit('dialogConfirm')
    }
    return {
      handleClose,
      handleConfirm,
      visible,
      subContent
    }
  }
})
