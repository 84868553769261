import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "share-dropdown-menu-wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{active: _ctx.isActive}, "share-dropdown-menu"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "share-dropdown-menu-title",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickTitle && _ctx.clickTitle(...args)), ["self"]))
      }, _toDisplayString(_ctx.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "share-dropdown-menu-item"
        }, [
          (item.href)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: item.href,
                target: "_blank"
              }, _toDisplayString(item.title), 9, _hoisted_2))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.title), 1))
        ]))
      }), 128))
    ])
  ], 2))
}