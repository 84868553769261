import { CommonMutationTypes } from './mutation-types'
import { CommonState } from './state'

export interface Mutations {
  [CommonMutationTypes.SET_MONEY_TYPE](
    state: CommonState,
    moneyType: string
  ): void
  [CommonMutationTypes.SET_EXCHANGE_RATE](
    state: CommonState,
    exchangeRate: number
  ): void
}

export const mutations = {
  [CommonMutationTypes.SET_MONEY_TYPE] (
    state: CommonState,
    moneyType: string
  ): void {
    state.moneyType = moneyType
  },
  [CommonMutationTypes.SET_EXCHANGE_RATE] (
    state: CommonState,
    exchangeRate: number
  ): void {
    state.exchangeRate = exchangeRate
  }
}
