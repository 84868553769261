
import { DeviceType } from '@/store/modules/app/state'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import webSocket from '@/mqtt'
import { AppActionTypes } from '@/store/modules/app/action-types'
import {
  AppMain,
  Navbar,
  Settings,
  TagsView,
  Sidebar,
  Notice
} from './components'
import RightPanel from '@/components/right_panel/Index.vue'
import resize from './resize'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessageBox, ElMessage } from 'element-plus'
import storage, { StorageType } from '@/utils/storage'
import dayjs from 'dayjs'
import { baseClient } from '@/apollo-client'
import VerifyPwd from '@/components/verify-pwd/index.vue'
import Verify2FADialog from '@/components/two-fa/index.vue'
import Subscribe from './components/subscribe/index.vue'
import useQuotPackage from '@/hooks/useQuotPackage'
import { Market } from '@/hooks/useStock'
import _ from 'lodash'
import useVerifyPwd from '@/hooks/useVerifyPwd'
import revisePwd from '@/components/revise-pwd/index.vue'

export default defineComponent({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    Notice,
    VerifyPwd,
    Verify2FADialog,
    Subscribe,
    revisePwd
  },
  setup() {
    const messageBox = ElMessageBox
    const { t } = useI18n()
    const store: any = useStore()
    const route = useRoute()
    const router = useRouter()
    const {
      sidebar,
      device,
      addEventListenerOnResize,
      resizeMounted,
      removeEventListenerResize,
      watchRouter
    } = resize()

    const state = reactive({
      handleClickOutside: () => {
        store.dispatch(AppActionTypes.ACTION_CLOSE_SIDEBAR, false)
      },
      compareTimer: '' as any,
      requestTimer: '' as any,
      timerFlag: true,
      timerFlag1: false,
      mouseFlag: true,
      extendSessionFlag: true,
      dataStr: '' as any,
      showMarquee: false,
      list: {
        title: '行情赠送提示',
        content:
          '如您当月有登录账号，则赠送当月港美股实时行情套餐。如您超过30天没有登录账号，则次月将不在赠送实时行情，直至您再次登录。如有疑问，可联系客服'
      },
      // dialogVisible: false,
      subDialogVisible: false,
      subList: {
        title: '更新提示-市场数据订阅/取消协议',
        tips: '以下为「市场数据订阅/取消协议」的条款及细则。如阁下有意透过网页版及/或APP存取、接收及使用由金马证券有限公司（「金马证券」）免费提供的市场数据，请细阅以下「市场数据订阅/取消协议」。当阁下按下「同意」后，即表示阁下已细阅、明白及同意下列所载的所有条款及细则。<p>请阁下阅读以下全部内容<span class="orange">并滑动到最底部</span></p>'
      },
      pwdDialogVisible: false, // 交易交易密码
      pwdLoading: false,
      loading: false,
      twoFaDialogVisible: false,
      userData: {
        phone: '',
        areaCode: ''
      },
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
      immigrantMainAccout: storage.rcGetItem(
        StorageType.local,
        'immigrantMainAccout'
      ),
      name: storage.rcGetItem(StorageType.local, 'userName'),
      professional: '1',
      pwdFlag: false
    })
    const classObj = computed(() => {
      return {
        hideSidebar: !sidebar.value.opened,
        openSidebar: sidebar.value.opened,
        withoutAnimation: sidebar.value.withoutAnimation,
        mobile: device.value === DeviceType.Mobile
      }
    })

    const showSettings = computed(() => {
      return store.state.settings.showSettings
    })
    const showTagsView = computed(() => {
      return store.state.settings.showTagsView
    })
    const fixedHeader = computed(() => {
      return store.state.settings.fixedHeader
    })

    watchRouter()
    onBeforeMount(() => {
      addEventListenerOnResize()
    })

    const {
      verifyData,
      submitCallback,
      loading: submitLoading
    } = useVerifyPwd()

    const { queryAccData, quotStatus } = useQuotPackage()

    const { mutate } = useMutation(gql.extendSession)
    const extendSessionFnc = () => {
      const { path } = route
      if (!path.includes('login')) {
        state.requestTimer = setInterval(() => {
          const sessionId = storage.rcGetItem(StorageType.local, 'sessionId')
          if (!sessionId) {
            console.log('没有id了')
            clearTimeout(state.requestTimer)
            return
          }
          if (!state.timerFlag) {
            return
          }
          if (!state.mouseFlag) {
            return
          }
          mutate().then((res: any) => {
            const { extendSession } = res.data
            if (extendSession.ok) {
              state.mouseFlag = false
              state.dataStr = new Date(
                dayjs(extendSession.data.expireTime).toDate()
              ).getTime()
              console.log(state.dataStr, 'state.dataStr')
            }
          })
        }, 60000)
      }
    }
    extendSessionFnc()

    const dobTime = () => {
      const { path } = route
      if (!path.includes('login')) {
        state.compareTimer = setInterval(() => {
          const sessionId = storage.rcGetItem(StorageType.local, 'sessionId')
          if (!sessionId) {
            console.log('倒计时回不去了')
            clearTimeout(state.compareTimer)
            return
          }
          if (state.dataStr === '') {
            return
          }
          const num = state.dataStr - new Date().getTime()
          if (num <= 60000 && num > 0) {
            if (!state.timerFlag) {
              return
            }
            state.timerFlag = false
            messageBox
              .confirm(
                '您已闲置超过29分钟, 系统将会在1分钟后自动退出, 如您想继续使用本系统, 请点击"继续"按钮',
                '提示',
                {
                  confirmButtonText: '继续',
                  cancelButtonText: '取消',
                  center: true,
                  customClass: 'message-info-change',
                  showClose: false,
                  closeOnClickModal: false,
                  type: 'warning'
                }
              )
              .then(() => {
                mutate().then((res: any) => {
                  const { extendSession } = res.data
                  if (extendSession.ok) {
                    state.mouseFlag = false
                    state.timerFlag = true
                    state.dataStr = new Date(
                      dayjs(extendSession.data.expireTime).toDate()
                    ).getTime()
                    console.log(state.dataStr, 'state.dataStr')
                  }
                })
              })
              .catch(() => ({}))
          }
          if (num <= 0) {
            if (state.timerFlag1) {
              return
            }
            state.timerFlag1 = true
            clearTimeout(state.compareTimer)
            clearTimeout(state.requestTimer)
            messageBox.close()
            webSocket.close().then(() => {
              storage.rcRemoveItem(StorageType.local, 'sessionId')
              const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
              const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
              // 目前只有投资移民主子账号公用主交易密码
              if (immigrantMainAccout && [9, 10].includes(currentClient.clientType) && currentClient.fundAccountType === 3) {
                storage.rcRemoveItem(
                  StorageType.session,
                  `unlock-immigrant-${immigrantMainAccout}`
                )
              } else {
                storage.rcRemoveItem(
                  StorageType.session,
                  `unlock-${currentClient.clientId}`
                )
              }

              storage.rcRemoveItem(StorageType.session, 'reminder')
              ElMessageBox.alert('', '系统页面已失效, 你已退出页面', {
                confirmButtonText: '我知道了',
                showClose: false,
                autofocus: false,
                customClass: 'message-info-out',
                confirmButtonClass: 'message-info-btn',
                center: true,
                type: 'warning',
                callback: () => {
                  router.push(
                    `/login?redirect=${router.currentRoute.value.fullPath}`
                  )
                  ElMessage({
                    message: '退出登录成功！',
                    type: 'success',
                    customClass: 'custom-success'
                  })
                }
              })
            })
          }
        }, 1000)
      }
    }
    dobTime()
    const { mutate: extendTimeClientFunc, onDone: extendTimeClientDone } =
      useMutation(gql.extendTimeClient)
    const extendTime = () => {
      const unlock = storage.rcGetItem(
        StorageType.session,
        `unlock-${state.fundAccount}`
      )
      const unlockImmigrant = storage.rcGetItem(
        StorageType.session,
        `unlock-immigrant-${state.immigrantMainAccout}`
      )
      const params = {
        params: {
          extendInfo: [
            {
              clientId: state.fundAccount,
              extendTime: 30
            }
          ]
        }
      }
      if (unlockImmigrant) {
        params.params.extendInfo[0].clientId = state.immigrantMainAccout
        extendTimeClientFunc(params)
      }
      if (unlock) {
        extendTimeClientFunc(params)
      }
    }
    // 鼠标
    const extendSession = () => {
      if (!state.timerFlag) {
        state.mouseFlag = false
        return
      }
      state.mouseFlag = true
    }

    extendTimeClientDone((res) => {
      const { extendTimeClient } = res.data
      const time = extendTimeClient?.data[0].tradeExpireTime
      const t = new Date(time).getTime()
      const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
      const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
      // 目前只有投资移民主子账号公用主交易密码
      if (immigrantMainAccout && [9, 10].includes(currentClient.clientType) && currentClient.fundAccountType === 3) {
        storage.rcSetItem(
          StorageType.session,
          `time-immigrant-${immigrantMainAccout}`,
          t
        )
      } else {
        storage.rcSetItem(
          StorageType.session,
          `time-${currentClient.clientId}`,
          t
        )
      }

      console.log(
        'extendTimeClient',
        extendTimeClient?.data[0].tradeExpireTime
      )
    })
    const onShowNotice = () => {
      if (storage.rcGetItem(StorageType.session, 'reminder')) {
        // 缓存有 不显示
        state.showMarquee = false
      } else {
        if (!store.getters.noticeList.length) {
          state.showMarquee = false
        } else {
          console.log(11111)
          state.showMarquee = true
        }
      }
    }
    watch(
      () => store.getters.noticeList,
      (newVal) => {
        onShowNotice()
      }
    )
    watch(
      () => store.state.quot.isDelayQuot,
      () => {
        const params = {
          stockPage: {
            exchangeType: Market.HK,
            fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
          },
          fundAcc: {
            exchangeType: Market.HK,
            fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
          }
        }
        queryAccData(gql.homeAccData, params)
      }
    )

    const changeRadio = (val: string) => {
      state.professional = val
    }
    const recordPromptAction = (
      operationType = 1,
      promptType = 2,
      professional = '1',
      loginFrom = 2
    ) => {
      const account = storage.rcGetItem(StorageType.local, 'userInfo')
      const variables = {
        param: {
          tradeAccount: account,
          promptType,
          operationType,
          professional,
          loginFrom,
          giveMonth: dayjs().format('YYYY-MM')
        }
      }
      baseClient
        .mutate({
          mutation: gql.recordPromptAction,
          variables
        })
        .then((res) => {
          const { recordPromptAction } = res.data
          if (recordPromptAction?.ok) {
            quotStatus.value = false
          }
        })
    }

    const subDialogClose = () => {
      quotStatus.value = false
      recordPromptAction(1, 1, state.professional)
    }
    const dialogConfirm = () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      towFAConfirm()
      // const currentClient = storage.rcGetItem(
      //   StorageType.local,
      //   'currentClient'
      // )
      // const need2Fa = currentClient.need2Fa
      // if (need2Fa) {
      //   setTimeout(() => {
      //     quotStatus.value = false
      //   }, 50)
      //   verifyData.verifyDialog = true
      // }
    }

    const revisePwd = (val: boolean) => {
      state.pwdFlag = val
    }

    const towFAConfirm = () => {
      recordPromptAction(2, 1, state.professional)
      ElMessage({
        message:
          '感谢阁下选择使用由金马证券免费提供的市场数据。阁下现可立即使用有关市场数据，作投资或参考用途。',
        type: 'success',
        customClass: 'custom-success'
      })
    }

    onMounted(() => {
      webSocket.init()
      resizeMounted()
      window.addEventListener('mousemove', extendSession)
      window.addEventListener('keydown', extendSession)
      window.addEventListener('click', extendSession)
      window.addEventListener('mousemove', _.throttle(extendTime, 1000 * 60))
      onShowNotice()
      state.userData = storage.rcGetItem(StorageType.local, 'userData')
    })
    onUnmounted(() => {
      window.removeEventListener('mousemove', extendSession)
      window.removeEventListener('keydown', extendSession)
      window.removeEventListener('click', extendSession)
    })

    onBeforeUnmount(() => {
      removeEventListenerResize()
    })
    const closeNotice = (val: boolean) => {
      state.showMarquee = val
    }
    return {
      t,
      ...toRefs(state),
      classObj,
      sidebar,
      showSettings,
      showTagsView,
      fixedHeader,
      closeNotice,
      subDialogClose,
      dialogConfirm,
      towFAConfirm,
      changeRadio,
      quotStatus,
      queryAccData,
      verifyData,
      submitCallback,
      submitLoading,
      revisePwd
    }
  }
})
