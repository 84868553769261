import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarLogo = _resolveComponent("SidebarLogo")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'has-logo': _ctx.showLogo}, "sideWrap"])
  }, [
    (_ctx.showLogo)
      ? (_openBlock(), _createBlock(_component_SidebarLogo, {
          key: 0,
          collapse: _ctx.isCollapse
        }, null, 8, ["collapse"]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_scrollbar, { "wrap-class": "scrollbar-wrapper" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu, {
          "default-active": _ctx.activeMenu,
          collapse: !_ctx.isCollapse,
          class: _normalizeClass([_ctx.isCollapse ? 'el-menu-vertical-demo' : '']),
          "unique-opened": "",
          router: "",
          style: {"--el-menu-hover-bg-color":"#fff7e5"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_sub_menu, { index: "/lpoa" }, {
              title: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("i", { class: "menu-icon lpoa" }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[1] || (_cache[1] = _createElementVNode("span", null, "WEB交易", -1))
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, {
                  index: "/trade/bulkTrading",
                  style: {"--el-menu-active-color":"#ffb400"}
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("买入/卖出")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "/trade/statement",
                  style: {"--el-menu-active-color":"#ffb400"}
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("我的结单")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_sub_menu, { index: "/currency" }, {
              title: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "menu-icon currency-icon" }, null, -1)
                  ])),
                  _: 1
                }),
                _cache[5] || (_cache[5] = _createElementVNode("span", null, "货币兑换", -1))
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, {
                  index: "/currency/conversion",
                  style: {"--el-menu-active-color":"#ffb400"}
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("兑入/兑出")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_menu_item, {
                  index: "/currency/record",
                  style: {"--el-menu-active-color":"#ffb400"}
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("兑换记录")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["default-active", "collapse", "class"])
      ]),
      _: 1
    })
  ], 2))
}