import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "tags-view-container",
  class: "tags-view-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ScrollPane = _resolveComponent("ScrollPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ScrollPane, {
      ref: "scrollPaneRef",
      class: "tags-view-wrapper",
      onScroll: _ctx.handleScroll
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitedViews, (tag) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            ref_for: true,
            ref: "tag",
            key: tag.path,
            class: _normalizeClass([_ctx.isActive(tag) ? 'active' : '', "tags-view-item"]),
            to: { path: tag.path, query: tag.query, fullPath: tag.fullPath },
            tag: "span",
            onMouseup: _withModifiers(($event: any) => (!_ctx.isAffix(tag) ? _ctx.closeSelectedTag(tag) : ''), ["middle"]),
            onContextmenu: _withModifiers(($event: any) => (_ctx.openMenu(tag, $event)), ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(tag.meta.title) + " ", 1),
              (!_ctx.isAffix(tag))
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    class: "el-icon-close",
                    onClick: _withModifiers(($event: any) => (_ctx.closeSelectedTag(tag)), ["prevent","stop"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Close)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class", "to", "onMouseup", "onContextmenu"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onScroll"]),
    _withDirectives(_createElementVNode("ul", {
      style: _normalizeStyle({ left: _ctx.left + 'px', top: _ctx.top + 'px' }),
      class: "contextmenu"
    }, [
      (!_ctx.isAffix(_ctx.selectedTag))
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeSelectedTag(_ctx.selectedTag)))
          }, _toDisplayString(_ctx.t('tagsView.close')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("li", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeOthersTags && _ctx.closeOthersTags(...args)))
      }, _toDisplayString(_ctx.t('tagsView.closeOthers')), 1),
      _createElementVNode("li", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeAllTags(_ctx.selectedTag)))
      }, _toDisplayString(_ctx.t('tagsView.closeAll')), 1)
    ], 4), [
      [_vShow, _ctx.visible]
    ])
  ]))
}