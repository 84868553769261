import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dropdown, {
      id: "size-select",
      trigger: "click",
      onCommand: _ctx.handleSetSize
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sizeOptions, (item) => {
              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: item.value,
                disabled: _ctx.size===item.value,
                command: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "command"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("div", null, [
          _createElementVNode("svg", {
            class: "icon",
            "aria-hidden": "true",
            "font-size": "40px"
          }, [
            _createElementVNode("use", { "xlink:href": "#iconshiliangzhinengduixiang" })
          ])
        ], -1))
      ]),
      _: 1
    }, 8, ["onCommand"]),
    _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
  ]))
}