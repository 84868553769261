import * as types from './mutations-type'
import * as gql from '@/graphql'
import { useQuery } from '@vue/apollo-composable'
import _ from 'lodash'

type TopicInter = {
  type?: number
  data: string[]
}

export enum SubType {
  sub,
  unSub,
  close
}

const state = {
  quot: {},
  orderList: '111',
  topic: [],
  noticeList: [],
  isDelayQuot: '',
  timeIsDst: false, // 夏冬令时
  currencyMessage: ''
}
const getters = {
  quot: (state: any) => state.quot,
  topic: (state: any) => state.topic,
  noticeList: (state: any) => state.noticeList,
  isDelayQuot: (state: any) => state.isDelayQuot,
  timeIsDst: (state: any) => state.timeIsDst,
  currencyMessage: (state: any) => state.currencyMessage
}
const mutations = {
  [types.QUOT_DETAIL] (state: any, payload: any) {
    state.quot = { ...state.quot, detail: payload.detail }
  },
  [types.ASKBID5] (state: any, payload: any) {
    state.quot = { ...state.quot, askBid: payload.askBid }
  },
  [types.GET_ORDERS] (state: any, payload: any) {
    state.orderList = payload
  },
  [types.IS_DELAY_QUOT] (state: any, payload: any) {
    state.isDelayQuot = payload
  },
  [types.TOPIC] (state: any, payload: TopicInter) {
    switch (payload.type) {
      case SubType.sub:
        state.topic = _.uniqWith([...state.topic, ...payload.data])
        break
      case SubType.unSub:
        state.topic = _.difference(state.topic, payload.data)
        break
      case SubType.close:
        state.topic = []
        break
      default:
        break
    }
  },
  [types.GET_NOTICE_LIST] (state: any, payload: any) {
    const index = state.noticeList.findIndex((item: any) => item.id === payload.id)
    state.noticeList.splice(index, 1)
    state.noticeList = [...payload, ...state.noticeList].filter((item: any) => item.status === 2 || !item.status)
  },
  [types.TIME_IS_DST] (state: any, payload: any) {
    state.timeIsDst = payload
  },
  [types.CURRENCY_LIST] (state: any, payload: any) {
    state.currencyMessage = payload
  }
}
const actions = {
  getList (context: any, hisOriginalParams: any) {
    return new Promise((resolve) => {
      const { onResult } = useQuery(gql.HisOriginal, hisOriginalParams, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
      })
      onResult(res => {
        if (res.data) {
          resolve(res.data)
        }
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
