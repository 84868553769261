import CryptoJS from 'crypto-js'
import NodeRSA from 'node-rsa'

const HMACSHA256_KEY = process.env.VUE_APP_H5_APPSECRET

const RSA_BASE64_KEY = process.env.VUE_APP_H5_RSA
// const RSA_BASE64_KEY = 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUF1YzRqNDFhYVlPZUZkaXRHYWdHVAoxY3JSdmF4VWd3aEF1bm5icytrVURaQndmdWs5TXpmRVF6eEVQVzdDREhidFhlZjhWMGFXaHNFOW9sS1NUTzg0CjVFdTcxTHhQeTBQWFJPUW1GRTFRc3NCdGZVZkhCditXcGdBL0prUkRjOC82cEpSTWowRmFMN0tsaUduTWI0SCsKR25WcXVJb0plQ2VkWUZ2Q0hzVzBPZUE2TGg1TkYxYWJCR2ZUZDBud3IyM2ZXZlp2R01OUzIvYnk3dGR6akFCbQpEQTlIcElJNFRXY1FIMjdEbUFCZ2FILzRSQS9FT0lOTXFsWnRFdEozODgydWE3VmUrekh6cVdpK2Yvck1iYmdECjl3cUhtWHZPNmJIU2ZvaDRuck5vVTJYS3dtdWNoUmFUTC92SllxN0RCNVRZNVVhb1lOODZ2dVlldHNFMkdLYWEKVndJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg=='

// sha512加密
export function sha512Encrypt (str: string): string {
  return CryptoJS.SHA512(str).toString()
}

// sha256加密
export function sha256Encrypt (str: string): string {
  return CryptoJS.SHA256(str).toString()
}

// hmacsha256加密
export function hmacSha256Encrypt (str: string, key = HMACSHA256_KEY): string {
  return CryptoJS.HmacSHA256(str, key).toString()
}

// base64加密
export function base64Encrypt (str: string) {
  const word = CryptoJS.enc.Utf8.parse(str)
  return CryptoJS.enc.Base64.stringify(word)
}

// base64解密
export function base64Decrypt (str: string) {
  const password = CryptoJS.enc.Base64.parse(str)
  return password.toString(CryptoJS.enc.Utf8)
}

// RSA加密
export function rsaEncrypt (str: string) {
  const key = base64Decrypt(RSA_BASE64_KEY as string)
  const rsa = new NodeRSA(key)
  return rsa.encrypt(str, 'base64')
}
