import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "dialogVisible-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_reset_pwd = _resolveComponent("reset-pwd")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      class: "revise-dialog",
      "model-value": _ctx.visible,
      width: "500px",
      title: "修改交易密码",
      onClose: _ctx.close,
      "show-close": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "title" }, "修改交易密码", -1)),
          _createElementVNode("div", {
            class: "forget",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openReset && _ctx.openReset(...args)))
          }, "忘记交易密码")
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          style: {"--el-button-hover-bg-color":"none"},
          class: "btn-canel",
          onClick: _ctx.close
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          loading: _ctx.loading,
          class: "btn-confirm",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirm(_ctx.ruleFormRef))),
          type: "primary"
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("确认修改")
          ])),
          _: 1
        }, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form, {
            ref: "ruleFormRef",
            rules: _ctx.rules,
            model: _ctx.state
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { prop: "pwdOld" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    class: "newPwd",
                    modelValue: _ctx.state.pwdOld,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.pwdOld) = $event)),
                    placeholder: "请输入原密码",
                    "show-password": "",
                    maxlength: "12",
                    size: "large"
                  }, {
                    prefix: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("div", { class: "input-pre" }, "原密码", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "pwdNew" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    class: "newPwd",
                    modelValue: _ctx.state.pwdNew,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.pwdNew) = $event)),
                    placeholder: "请输入新密码",
                    "show-password": "",
                    autocomplete: "off",
                    maxlength: "12",
                    size: "large"
                  }, {
                    prefix: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("div", { class: "input-pre" }, "新密码", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "pwdNew2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    class: "newPwd2",
                    modelValue: _ctx.state.pwdNew2,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.pwdNew2) = $event)),
                    placeholder: "请输入确认密码",
                    "show-password": "",
                    maxlength: "12",
                    size: "large"
                  }, {
                    prefix: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("div", { class: "input-pre" }, "确认新密码", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["rules", "model"]),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "tips" }, "密码长度为8-12位，必须包含大小写字母和数字", -1))
        ])
      ]),
      _: 1
    }, 8, ["model-value", "onClose"]),
    (_ctx.state.resetPwdFlag)
      ? (_openBlock(), _createBlock(_component_reset_pwd, {
          key: 0,
          dialogVisible: _ctx.state.resetPwdFlag,
          "onUpdate:dialogVisible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.resetPwdFlag) = $event))
        }, null, 8, ["dialogVisible"]))
      : _createCommentVNode("", true)
  ], 64))
}