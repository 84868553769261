
import dayjs from 'dayjs'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FargoSearch',
  props: {
    searchFormData: { type: Object, default: () => ({}) },
    searchList: { type: Array, default: () => [] }
  },
  emits: ['update:searchFormData', 'search'],
  setup (props, { emit }) {
    const date = ref()
    const searchFormDataComputed = computed({
      get: () => props.searchFormData,
      set: val => {
        emit('update:searchFormData', val)
      }
    })
    /*
     *@Description: 传值
     *@param: {*}
     *@return: {*}
     */
    const eventFn = (val: any, item: any) => {
      if (date.value) {
        props.searchFormData.startDate = dayjs(date.value[0]).format('YYYYMMDD')
        props.searchFormData.endDate = dayjs(date.value[1]).format('YYYYMMDD')
      }
      emit('update:searchFormData', props.searchFormData)
    }
    /*
     *@Description: 搜索
     *@param: {*}
     *@return: {*}
     */
    const search = (ref: string, btnType: string) => {
      emit('search', btnType)
    }
    return {
      search,
      searchFormDataComputed,
      eventFn,
      date
    }
  }
})
