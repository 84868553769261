
import { useMutation } from '@vue/apollo-composable'
import { computed, defineComponent, nextTick, ref } from 'vue'
import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import * as paramsWrap from '@/graphql/params-wrap'
import { DesensitizationType } from '@/utils/desensitization'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Unlock',
  props: {
    unlockDialog: {
      type: Boolean,
      default: false
    },
    time: {
      type: Number,
      default: 90
    }
  },
  emits: ['update:unlockDialog', 'unlockFlagClick'],
  setup(props, { emit }) {
    const password = ref()
    const timeDay = ref(90)
    const editPwdDialog = ref(false)
    const reviseDialog = ref(false)
    const endTimeDialog = ref(false)
    const resetDialog = ref(false)
    const pwdContent = ref('交易账号首次登录请修改密码')
    const { mutate: loginClient, onDone: loginClientDone, loading: submitLoading } = useMutation(gql.loginClient)
    const { mutate: updateTime } = useMutation(gql.updatePwdExpiryDate)

    const close = () => {
      emit('update:unlockDialog', false)
      password.value = ''
    }

    const closeEdit = () => {
      editPwdDialog.value = false
      nextTick(() => {
        reviseDialog.value = true
      })
    }

    const resetPwd = () => {
      close()
      nextTick(() => {
        resetDialog.value = true
      })
    }

    const canelTimeUpdate = () => {
      const params = {
        param: {
          clientId: storage.rcGetItem(StorageType.local, 'userInfo')
        }
      }
      updateTime(params)
      endTimeDialog.value = false
    }

    const updatePwd = () => {
      endTimeDialog.value = false
      nextTick(() => {
        reviseDialog.value = true
      })
    }

    const content = computed(() => {
      return `你的交易密码距离上次修改已超过${timeDay.value}天。为加强网络安全，请尽快修改你的交易密码！`
    })

    const contentBtn = computed(() => {
      return `请于${timeDay.value}天后再提醒`
    })

    const submitPasswordUnlock = () => {
      if (!password.value) return
      const param = {
        params: {
          clientId: storage.rcGetItem(StorageType.local, 'userInfo'),
          tradePassword: paramsWrap.wrapSign(password.value, {
            type: DesensitizationType.full
          })
        }
      }
      const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
      if (immigrantMainAccout) {
        param.params.clientId = immigrantMainAccout
      }
      const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
      if (currentClient?.fundAccountType === 30) {
        param.params.clientId = currentClient.clientId
      }
      loginClient(param)
    }

    loginClientDone((res) => {
      const { loginClient } = res?.data
      if (loginClient.ok) {
        const currentClient = storage.rcGetItem(StorageType.local, 'currentClient')
        const immigrantMainAccout = storage.rcGetItem(StorageType.local, 'immigrantMainAccout')
        // 目前只有投资移民主子账号公用主交易密码
        if (immigrantMainAccout && [9, 10].includes(currentClient.clientType) && currentClient.fundAccountType === 3) {
          storage.rcSetItem(StorageType.session, `unlock-immigrant-${immigrantMainAccout}`, 1)
          storage.rcSetItem(StorageType.session, `time-immigrant-${immigrantMainAccout}`, dayjs().add(30, 'minute').valueOf())
        } else {
          storage.rcSetItem(StorageType.session, `unlock-${currentClient.clientId}`, 1)
          storage.rcSetItem(StorageType.session, `time-${currentClient.clientId}`, dayjs().add(30, 'minute').valueOf())
        }

        emit('unlockFlagClick', true)
        close()
        // 修改交易密码提醒
        if (loginClient.data?.passwordExpiryAlert) {
          timeDay.value = loginClient.data.passwordAlertCycle
          nextTick(() => {
            endTimeDialog.value = true
          })
        }
      } else {
        // 首次登录or重置密码
        if (Number(loginClient.reason.err) === 3) {
          close()
          nextTick(() => {
            pwdContent.value = loginClient.reason.desc
            editPwdDialog.value = true
          })
          return
        }
        ElMessage({
          dangerouslyUseHTMLString: true,
          message: loginClient.reason?.desc?.replaceAll('\n', '</br>'),
          type: 'error'
        })
      }
    })
    return {
      password,
      timeDay,
      editPwdDialog,
      reviseDialog,
      endTimeDialog,
      resetDialog,
      submitPasswordUnlock,
      close,
      closeEdit,
      content,
      contentBtn,
      resetPwd,
      canelTimeUpdate,
      updatePwd,
      submitLoading,
      pwdContent
    }
  }
})
