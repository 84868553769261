
import { defineComponent, ref } from 'vue'
import useRestPwd from '@/hooks/useRestPwd'
export default defineComponent({
  name: 'ResetPwd',
  props: {
    dialogVisible: { type: Boolean, default: false }
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup(props, { emit }) {
    const ruleFormRef = ref()
    const { state, rules, sendSmsHandle, confirm, sendSmsLoading, loading } = useRestPwd(emit)
    const close = () => {
      state.idCard = ''
      state.phone = ''
      state.areaCode = ''
      state.smsCode = ''
      state.email = ''
      state.smsBtnStatus = false
      emit('update:dialogVisible', false)
    }

    return {
      state,
      close,
      confirm,
      ruleFormRef,
      rules,
      sendSmsHandle,
      sendSmsLoading,
      loading
    }
  }
})
