import { store } from '@/store'
import {
  transformSocket2,
  transformSocket3,
  transformSocket21
} from '@/utils/formatStock'
import mqtt from 'mqtt'
import * as quotType from '@/store/modules/quot/mutations-type'
import { utf8ArrayToStr } from '@/utils'
import storage, { StorageType } from '@/utils/storage'
import signWrap from '@/apollo-client/sign'
import qs from 'qs'
import { nanoid } from 'nanoid'
import router from '@/router'
import { ElMessage, ElMessageBox } from 'element-plus'
import { SubType } from '@/store/modules/quot'
import useClearUnlock from '@/hooks/useClearUnlock'

class Socketer {
  socket: any
  closeStatus: boolean
  reconnectionFlag: boolean
  index: any
  constructor () {
    this.socket = null
    this.closeStatus = false
    this.reconnectionFlag = false
    this.index = 1
  }

  init () {
    const that = this
    const sessionId = storage.rcGetItem(StorageType.local, 'sessionId')
    const urlParams = qs.stringify(signWrap())
    const connectUrl = `${process.env.VUE_APP_H5_MQTT_URL}?${urlParams}`
    const options = {
      clientId: `gh_${sessionId}`,
      keepalive: 10,
      reconnectPeriod: 0,
      username: process.env.VUE_APP_H5_MQTT_USERNAME,
      password: process.env.VUE_APP_H5_MQTT_PASSWORD
    }

    if (!this.socket) {
      this.socket = mqtt.connect(connectUrl, options)
    }

    this.socket.on('connect', () => {
      const topic = `login/user/${sessionId}`
      const data = store.getters?.topic || []
      if (!data.includes(topic)) {
        this.subscribe([topic])
      }
      this.subscribe(data)
      // data.forEach((val: string) => {
      //   that.subscribe([val])
      // })
      that.reconnectionFlag = true
      that.index = 1
      console.log('Connection succeeded!')
    })
    this.socket.on('error', (error: any) => {
      console.log('Connection failed', error)
    })
    this.socket.on('message', (topic: string, message: any) => {
      const result = utf8ArrayToStr(message)
      that.hanldeDataType(result)
      // console.log(`Received message ${message} from topic ${topic}`)
    })
    this.socket.on('reconnect', function () {
      console.log('Reconnecting...')
    })
    this.socket.on('close', function () {
      console.log('Disconnected', that.reconnectionFlag)
      that.socket &&
        that.socket.end(true, null, function () {
          if (!that.reconnectionFlag) {
            that.index++
            if (that.index > 5) {
              console.log('大于五次了  => 开始关闭')
              // 关闭重连
              that.close().then(() => {
                ElMessageBox.alert('', '行情推送已断开, 请刷新页面重试', {
                  confirmButtonText: '我知道了',
                  showClose: false,
                  autofocus: false,
                  customClass: 'message-info-out',
                  confirmButtonClass: 'message-info-btn',
                  center: true,
                  type: 'warning',
                  callback: () => {
                    window.location.reload()
                  }
                })
              })
            }
          }
          that.reconnectionFlag = false
          that.socket = null
          // 主动关闭之后不再重连
          if (!that.closeStatus) {
            that.init()
          }
        })
    })
  }

  close () {
    const that = this
    this.closeStatus = true
    // 关闭成交推送、互踢推送
    const options = {
      type: SubType.close,
      data: []
    }
    store.commit(quotType.TOPIC, options)
    return new Promise<void>(resolve => {
      console.log(
        '%c [  ]-123',
        'font-size:13px; background:pink; color:#bf2c9f;',
        that.socket
      )
      if (that.socket) {
        that.socket.end(true, null, function () {
          that.socket = null
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  subscribe (topic: string[]) {
    const options = {
      type: SubType.sub,
      data: topic
    }
    store.commit(quotType.TOPIC, options)
    if (this.socket) {
      this.socket.subscribe(topic, { qos: 0 }, (error: any, res: any) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        console.log('Subscribe to topics res', res)
      })
    }
  }

  unsubscribe (topic: string[]) {
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.unsubscribe(topic, function (error: any) {
          if (error) {
            reject(new Error(error))
            console.log(error)
          } else {
            const options = {
              type: SubType.unSub,
              data: topic
            }
            store.commit(quotType.TOPIC, options)
            resolve(true)
            console.log('Unsubscribed')
          }
        })
      }
    })
  }

  hanldeDataType (data: any) {
    // console.log('websocket ==> 原始数据', data)
    const that = this
    if (!data) return false
    const { data: res, funId, msgType, msgGroup, msgContent } = JSON.parse(data)
    // 成交推送
    if (msgType) {
      switch (msgType) {
        case 'tradePush':
          // todo 成交推送逻辑
          console.log('do soming...成交推送逻辑')
          store.commit(quotType.GET_ORDERS, nanoid())
          if (JSON.parse(data).data.content) {
            ElMessage({
              message: JSON.parse(data).data.content,
              type: 'success',
              customClass: 'custom-success'
            })
          }
          break
        case 'loginOtherDevice':
        case 'tradeLoginOtherDevice':
          // todo 互踢
          that.close()
          storage.rcRemoveItem(StorageType.local, 'sessionId')
          useClearUnlock()
          storage.rcRemoveItem(StorageType.session, 'reminder')
          console.log('login other 互踢')
          ElMessageBox.alert(
            JSON.parse(data).data.msg,
            JSON.parse(data).data.title,
            {
              confirmButtonText: '确定',
              showClose: false,
              autofocus: false,
              customClass: 'message-info-device',
              confirmButtonClass: 'message-info-btn',
              center: true,
              type: 'warning',
              callback: () => {
                router.push(
                  `/login?redirect=${router.currentRoute.value.fullPath}`
                )
                ElMessage({
                  message: '退出登录成功！',
                  type: 'success',
                  customClass: 'custom-success'
                })
              }
            }
          )
          break
        default:
          break
      }
    } else if (funId) {
      // 行情推送
      switch (funId) {
        case 2:
          store.commit(quotType.QUOT_DETAIL, { detail: transformSocket2(res) })
          break
        case 3:
          store.commit(quotType.ASKBID5, { askBid: transformSocket3(res) })
          break
        case 21:
          store.commit(quotType.ASKBID5, { askBid: transformSocket21(res) })
          break
        default:
          break
      }
    } else {
      switch (msgGroup) {
        case 7:
          // 换汇推送
          // todo 货币兑换推送
          console.log('do soming...货币兑换推送')
          store.commit(quotType.CURRENCY_LIST, msgContent)
          store.commit(quotType.GET_ORDERS, nanoid())
          break
        case 11:
          store.commit(quotType.IS_DELAY_QUOT, nanoid())
          break
        // 公告推送
        case 8:
          const notice = JSON.parse(msgContent)
          const webTradeStatus = notice.clientList.filter(
            (data: any) => data.clientType === 3
          )
          if (webTradeStatus.length) {
            store.commit(quotType.GET_NOTICE_LIST, [
              {
                content: notice.content,
                title: notice.title,
                id: notice.id,
                status: notice.status,
                userType: notice.clientList[0].userType,
                clientLocations: notice.clientList[0].clientLocations
              }
            ])
          }
          console.log('notice', JSON.parse(String(msgContent)))
          break
        default:
          break
      }
    }
  }
}

export default new Socketer()
