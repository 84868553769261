import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dropdown, null, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (item) => {
              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: item.value,
                disabled: _ctx.language===item.value
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    onClick: ($event: any) => (_ctx.handleSetLanguage(item.value))
                  }, _toDisplayString(item.name), 9, _hoisted_1)
                ]),
                _: 2
              }, 1032, ["disabled"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(["icon", {'svg-color': _ctx.isWhite}]),
          "aria-hidden": "true",
          "font-size": "45px"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("use", { "xlink:href": "#iconzhongyingwen" }, null, -1)
        ]), 2))
      ]),
      _: 1
    })
  ]))
}