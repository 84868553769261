import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "verify-2FA-Dialog" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "area-code" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Lock = _resolveComponent("Lock")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      class: "verify-2FA-Dialog",
      width: "500px",
      "model-value": _ctx.verify2faDialog,
      title: "双重认证",
      onClose: _ctx.close,
      "show-close": false,
      "destroy-on-close": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            class: "canel",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            class: "sure",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("确定")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { prop: "phone" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.phone,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.phone) = $event)),
                    placeholder: "请输入手机号码",
                    autocompleted: "off",
                    autocomplete: "new-password",
                    size: "large",
                    readonly: ""
                  }, {
                    prefix: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, "+" + _toDisplayString(_ctx.state.areaCode), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { prop: "smsCode" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.smsCode,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.smsCode) = $event)),
                    placeholder: "请输入验证码",
                    autocompleted: "off",
                    autocomplete: "new-password",
                    size: "large",
                    maxlength: "6"
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Lock)
                        ]),
                        _: 1
                      })
                    ]),
                    suffix: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        loading: _ctx.sendCodeLoading,
                        class: _normalizeClass(['sms-code', _ctx.state.smsBtnStatus ? 'disable' : ''])
                      }, {
                        default: _withCtx(() => [
                          (_ctx.state.smsBtnStatus)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`${_ctx.state.countNum}后重新获取`), 1))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sendSmsHandle && _ctx.sendSmsHandle(...args)))
                              }, "获取验证码"))
                        ]),
                        _: 1
                      }, 8, ["loading", "class"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["model-value", "onClose"])
  ]))
}