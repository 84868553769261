<template>
  <div class="navbar">
    <Hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
      @toggle-click="toggleSideBar" />
    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <Screenfull v-if="false" class="right-menu-item hover-effect" />
        <LangSelect v-if="false" class="right-menu-item hover-effect" />
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" size="small" trigger="click">
        <div class="avatar-wrapper">
          <img :src="userIcon" class="user-avatar" />
          <span>
            {{ manager }}
          </span>
          <el-icon>
            <ArrowDown />
          </el-icon>
        </div>
        <template #dropdown>
          <div class="drop-down">
            <div class="switch">账户切换</div>
            <div :class="['client-item', manager === item.clientId ? 'bg' : '']" v-for="(item, i) in userData.clientList"
              @click="changeClient(item)">
              <img :src="userIcon" class="client-avatar" />
              <el-tooltip effect="dark" :content="getClientInfo(item).clientTypeText" placement="top">
                <div>
                  <div class="client-name"> {{ getClientInfo(item).fundAccountTypeText }}</div>
                  <div class="client-id">{{ item.clientId }}</div>
                </div>
              </el-tooltip>
              <div class="current-client" v-if="item.hostingStatus !== 0">
                {{ renderHostingStatus(item.hostingStatus) }}
              </div>
              <div class="current-client" v-if="manager === item.clientId">
                当前使用
              </div>
            </div>
            <div class="text border-top mt" @click="revisePwd" v-if="![10].includes(currentClient.clientType)">
              修改交易密码
            </div>
            <div class="text border-bottom">V0.6.500</div>
            <div class="text" @click="logout">退出登录</div>
          </div>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import storage, { StorageType } from '@/utils/storage'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'
import webSocket from '@/mqtt'
import useClearUnlock from '@/hooks/useClearUnlock'
import { CLIENTTYPE } from '@/constant'

export default defineComponent({
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const sidebar = computed(() => {
      return store.state.app.sidebar
    })
    const device = computed(() => {
      return store.state.app.device.toString()
    })
    const avatar = computed(() => {
      return store.state.user.avatar
    })

    const renderHostingStatus = (status) => {
      let tpl = ''
      switch (status) {
        case 1:
          tpl = '已委托'
          break
        case 2:
          tpl = '申请委托中'
          break
        default:
          break
      }
      return tpl
    }

    // const userData = computed(
    //   () => storage.rcGetItem(StorageType.local, 'userData') || {}
    // )
    // 退出登录
    const { mutate: registerDeviceFunc } = useMutation(gql.lpoaLogoutManager)
    const state = reactive({
      toggleSideBar: () => {
        store.dispatch(AppActionTypes.ACTION_TOGGLE_SIDEBAR, false)
      },
      logout: () => {
        // useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT)
        // 清除sessionId
        // registerDeviceFunc().then((res) => {
        //   console.log(result)
        // })
        webSocket.close().then(() => {
          storage.rcRemoveItem(StorageType.local, 'sessionId')
          useClearUnlock()
          storage.rcRemoveItem(StorageType.local, 'userType')
          storage.rcRemoveItem(StorageType.session, 'reminder')
          storage.rcRemoveItem(StorageType.local, 'userData')
          storage.rcRemoveItem(StorageType.local, 'userInfo')
          router.push(`/login?redirect=${route.fullPath}`).catch((err) => {
            console.warn(err)
          })
          ElMessage({
            message: '退出登录成功',
            type: 'success',
            customClass: 'custom-success'
          })
        })
      },
      userIcon: require('@/assets/images/home/user.png'),
      manager: storage.rcGetItem(StorageType.local, 'userInfo'),
      currentClient:
        storage.rcGetItem(StorageType.local, 'currentClient') || {},
      pwdFlag: false,
      userData: storage.rcGetItem(StorageType.local, 'userData') || {}
    })

    const revisePwd = () => {
      ElMessage({
        message: '您将对当前使用的账号进行修改',
        type: 'warning'
      })
      emit('revisePwd', true)
    }

    const changeClient = (item) => {
      if (state.manager === item.clientId) return
      storage.rcSetItem(StorageType.local, 'currentClient', item)
      storage.rcSetItem(StorageType.local, 'userName', item.clientName)
      storage.rcSetItem(StorageType.local, 'userInfo', item.clientId)
      window.location.reload()
    }

    const foundAccountTypeMap = {
      3: '现金',
      30: '保证金'
    }

    // 获取client的属性
    const getClientInfo = (item) => {
      const { clientType, hasSubClient, fundAccountType } = item
      let type = ''
      if ([5, 9].includes(clientType) && hasSubClient === 1) {
        type = '主'
      }

      if ([6, 7, 8, 10].includes(clientType)) {
        type = '子'
      }

      return {
        clientTypeText: CLIENTTYPE[clientType],
        fundAccountTypeText: `${foundAccountTypeMap[fundAccountType]}${type}账户`
      }
    }

    onMounted(() => {
      state.userData = storage.rcGetItem(StorageType.local, 'userData')
      state.currentClient = storage.rcGetItem(
        StorageType.local,
        'currentClient'
      )
    })

    return {
      sidebar,
      device,
      avatar,
      ...toRefs(state),
      t,
      revisePwd,
      changeClient,
      renderHostingStatus,
      getClientInfo
    }
  }
})
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  // overflow: hidden;
  // position: relative;
  background: #fff;
  // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    padding: 0 15px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {

    // float: right;
    // height: 100%;
    // line-height: 50px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      .avatar-wrapper {
        // margin-top: 5px;
        margin-right: 16px;
        margin-left: 16px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 10px;
        }

        span {
          display: flex;
          align-items: center;
          margin: 0 10px;
          font-size: 16px;
          color: #0f1423;
          font-weight: 600;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.drop-down {
  .switch {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    padding: 16px 16px 8px;
  }

  .text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    padding: 12px 0 12px 16px;
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: 1px solid #d9d9d9;
  }

  .border-top {
    border-top: 1px solid #d9d9d9;
  }

  .mt {
    margin-top: 8px;
  }

  .client-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    padding: 8px 16px;

    .client-avatar {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .client-name {
      font-size: 14px;
    }

    .client-id {
      font-size: 12px;
    }

    .current-client {
      width: 64px;
      height: 22px;
      background: #fffbe6;
      border-radius: 4px;
      border: 1px solid #fff1b8;

      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #faad14;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-align: center;
      margin-left: 8px;
    }

    &:hover {
      background: #fff7e5;
      color: #ffb400;
    }
  }

  .bg {
    background: #fff7e5;
    color: #ffb400;
  }
}
</style>
