import { createApp, provide, h } from 'vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { loadAllPlugins } from './plugins'
import '@/assets/iconfont/iconfont.css'
import '@/styles/index.scss'
import 'normalize.css'
import '@/permission'
import GC from './components'
import { ApolloClients } from '@vue/apollo-composable'
import { baseClient, dmzClient } from './apollo-client'

const app = createApp({
  setup () {
    provide(ApolloClients, {
      default: baseClient,
      dmz: dmzClient
    })
  },
  render: () => h(App)
})

// process.env.VUE_APP_IS_PROD === 'true' && Sentry.init({
//   app,
//   dsn: 'https://7662f81ee7064dfcae9c33cdd931224f@sentry.fwdev.top/9',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 加载所有插件
loadAllPlugins(app)
app.use(store).use(router).use(GC).mount('#app')
