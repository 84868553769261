
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Subscribe',
  props: {
    professional: {
      type: String,
      default: '1'
    },
    name: {
      type: String,
      default: ''
    },
    fundAccount: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const checked = ref(true)
    const changeRadio = (val: string) => {
      emit('changeRadio', val)
    }
    return {
      checked,
      changeRadio
    }
  }
})
