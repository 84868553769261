
import { defineComponent, nextTick, reactive, ref } from 'vue'

export default defineComponent({
  name: 'VerifyPwd',
  props: {
    verifyPwdDialog: {
      type: Boolean,
      default: false
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:verifyPwdDialog', 'submitCallback'],
  setup (props, { emit }) {
    const resetDialog = ref(false)
    const ruleFormRef = ref()

    const state = reactive({
      password: ''
    })

    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('请输入交易密码'))
      }
      callback()
    }

    const rules = reactive({
      password: [{ validator: validatePass, trigger: 'blur' }]
    })

    const close = () => {
      emit('update:verifyPwdDialog', false)
      state.password = ''
    }

    const resetPwd = () => {
      close()
      nextTick(() => {
        resetDialog.value = true
      })
    }

    const submitPassword = (formEl: any) => {
      formEl.validate((valid) => {
        if (valid) {
          emit('submitCallback', state.password)
          console.log('submit!')
        } else {
          console.log('error submit!')
          return false
        }
      })
    }

    return {
      state,
      resetDialog,
      submitPassword,
      close,
      resetPwd,
      ruleFormRef,
      rules
    }
  }
})
