
import { baseClient } from '@/apollo-client'
import { publishedNotices } from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch
} from 'vue'
import webSocket from '@/mqtt'
import { useStore } from '@/store'
import * as quotType from '@/store/modules/quot/mutations-type'

export default defineComponent({
  name: 'Notice',
  props: {
    showMarquee: {
      type: Boolean,
      default: true
    }
  },
  emits: ['closeNotice'],
  setup (props, { emit }) {
    const state = reactive({
      dialogVisible: false,
      noticeIndex: 0,
      currentNotice: {},
      notices: []
    })

    const store: any = useStore()

    const userType = computed(() => {
      return storage.rcGetItem(StorageType.local, 'userType')
    })

    const showNotice = computed(() => {
      return (
        state.notices.length > 0 &&
        !storage.rcGetItem(StorageType.session, 'reminder')
      )
    })

    watch(
      () => store.getters.noticeList,
      (list: any) => {
        console.log('noticeList', list)
        const some: any = []
        const allList = [...list, state.notices]
        allList.forEach((el: any) => {
          if (!some.some((e: any) => e.id === el.id)) {
            some.push(el)
          }
        })
        const enableNotices = some.filter((item: any) => item.userType !== 2)
        console.log(enableNotices, 'enableNotices')
        switch (userType.value) {
          case 3:
            state.notices = enableNotices.filter(
              (item: any) => item.userType === 3 || item.userType === 1
            )
            break
          case 4:
            state.notices = enableNotices.filter(
              (item: any) =>
                item.userType === 4 ||
                item.userType === 3 ||
                item.userType === 1
            )
            break
          case 5:
            state.notices = enableNotices.filter(
              (item: any) =>
                item.userType === 3 ||
                item.userType === 1 ||
                item.userType === 5
            )
            break
          default:
            state.notices = enableNotices
        }
      },
      { deep: true }
    )

    const getNotice = () => {
      baseClient
        .query({
          query: publishedNotices,
          variables: {
            filter: { clientType: 3 }
          },
          fetchPolicy: 'no-cache'
        })
        .then(res => {
          webSocket.subscribe(['push/public/transMsg'])
          store.commit(
            quotType.GET_NOTICE_LIST,
            res.data.publishedNotices.result
          )
          // state.notices = res.data.publishedNotices.result
        })
    }
    const changeNotice = (val: number) => {
      state.noticeIndex = val
    }
    const openDialog = () => {
      state.dialogVisible = true
      const index = state.noticeIndex
      state.currentNotice = state.notices[index]
    }

    const dialogClose = () => {
      state.dialogVisible = false
    }

    const closeMarquee = () => {
      emit('closeNotice', false)
      storage.rcSetItem(StorageType.session, 'reminder', true)
    }
    onMounted(() => {
      getNotice()
    })
    return {
      ...toRefs(state),
      closeMarquee,
      openDialog,
      changeNotice,
      showNotice,
      dialogClose
    }
  }
})
