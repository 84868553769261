import gql from 'graphql-tag'

/**
 * 搜索股票
 */
export const queryStkAssetInfo = gql`
  query($filter: FilterStkAssetInfoInput!) {
    stkAssetInfo(filter: $filter) {
      code
      result
      message
    }
  }
`

/**
 * 查询港股行情
 */
export const quotHk = gql`
  query($filter: FilterStkQuotVOInput!) {
    quotHk(filter: $filter) {
      code
      result
      message
    }
  }
`

/**
 * 查询美股行情
 */
export const quotUs = gql`
  query($filter: FilterStkQuotVOInput!) {
    quotUs(filter: $filter) {
      code
      result
      message
    }
  }
`
/**
 * 查询A股行情
 */
export const quotA = gql`
  query($filter: FilterStkQuotVOInput!) {
    quotA(filter: $filter) {
      code
      result
      message
    }
  }
`

/**
 * 获取设备 id
 */
export const registerDevice = gql`
  mutation($params: ParamReigsterDeiveInput!) {
    registerDevice(param: $params) {
      data {
        deviceId
        createTime
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 获取用户信息
 */
export const lpoaManagersBindInfoList = gql`
  query {
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`

/**
 * 退出登录
 */
export const lpoaLogoutManager = gql`
  mutation {
    lpoaLogoutManager {
      ok
      reason {
        desc
        err
      }
    }
  }
`

/**
 * 汇率
 */
export const lpoaForex = gql`
  query {
    lpoaForex {
      data {
        baseCcy
        ccy
        rate
      }
    }
  }
`

// 分割线 ----------------------------------------------
/**
 * 个人账户 买入/卖出 限价单(增强)
 */
export const placeEloOrder = gql`
  mutation($params: ParamPlaceEloOrderInput!) {
    placeEloOrder(param: $params) {
      ok
      data {
        entrustStatus
        orderNo
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人账户 买入/卖出 市价单
 */
export const placeMoOrder = gql`
  mutation($params: ParamPlaceMoOrderInput!) {
    placeMoOrder(param: $params) {
      ok
      data {
        entrustStatus
        orderNo
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人账户 买入/卖出 竞价限价单
 */
export const placeAloOrder = gql`
  mutation($params: ParamPlaceOrderInput!) {
    placeAloOrder(param: $params) {
      ok
      data {
        entrustStatus
        orderNo
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人账户 买入/卖出 竞价市价单
 */
export const placeAmoOrder = gql`
  mutation($params: ParamPlaceOrderNoPriceInput!) {
    placeAmoOrder(param: $params) {
      ok
      data {
        entrustStatus
        orderNo
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人账户 买入/卖出 限价单
 */
export const placeLoOrder = gql`
  mutation($params: ParamPlaceLoOrderInput!) {
    placeLoOrder(param: $params) {
      ok
      data {
        entrustStatus
        orderNo
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人账户 验证交易密码
 */
export const loginClient = gql`
  mutation($params: ParamLoginClientInput!) {
    loginClient(param: $params) {
      ok
      data {
        sessionId
        ghUserid
        loginIp
        clientId
        expireTime
        tradeExpireTime
        clientPasswordAlert
        passwordExpiryAlert
        passwordAlertCycle
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人账户 撤单
 */
export const revokeOrder = gql`
  mutation($params: ParamRevokeOrderInput!) {
    revokeOrder(param: $params) {
      ok
      data {
        orderNo
        entrustStatus
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 获取个人资金明细
 */
export const FundAccMarketList = gql`
  query($filter: FilterFundAccMarketListInput!) {
    FundAccMarketList(filter: $filter) {
      data {
        fundAccount
        assetProp
        moneyType
        exchangeType
        enableBalance
        enableBalanceDisplay
        positionIncome
        dailyIncome
        marketValue
        marketValueDisplay
        currentBalance
        frozenBalance
        frozenBalanceDisplay
        unfrozenBalance
        cashOnHold
        fetchBalance
        fetchBalanceDisplay
        transferBalance
        settledBalance
        unsettBalance
        asset
        assetDisplay
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 获取个人持仓
 */
export const StockPosPageList = gql`
  query($filter: FilterGetStockPosPageListInput!) {
    StockPosPageList(filter: $filter) {
      data {
        totalCount
        currentPage
        rows {
          exchangeType
          stockCode
          clientId
          fundAccount
          moneyType
          stockType
          beginAmount
          currentAmount
          uncomeBuyAmount
          uncomeSellAmount
          uncomeBuyAmountT1
          uncomeSellAmountT1
          uncomeBuyAmountT2
          uncomeSellAmountT2
          uncomeBuyAmountTn
          uncomeSellAmountTn
          frozenAmount
          sumBuyAmount
          sumBuyBalance
          sumSellAmount
          sumSellBalance
          costPrice
          costPriceDisplay
          entityAmount
          incomeBalance
          incomeBalanceDisplay
          enableAmount
          enableAmountDisplay
          stockName
          marketValue
          marketValueDisplay
          marginRatio
          marginValue
          realBuyAmount
          realSellAmount
          stockNameBig5
          stockNameGb
          closePrice
          stkcodeStatus
          suspendDays
          greyFlag
          greyMarketDate
          realBuyBalance
          realSellBalance
          currentPriceDisplay
          incomePercentDisplay
        }
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 获取个人今日订单
 */
export const HisOriginal = gql`
  query($filter: FilterGetHisOriginalInput!) {
    hisOriginal(filter: $filter) {
      data {
        totalCount
        currentPage
        rows {
          fundAccount
          clientId
          exchangeType
          moneyType
          orderNo
          orderTxnReference
          entrustNo
          stockCode
          stockName
          stockNameGb
          stockNameBig5
          entrustBs
          entrustProp
          entrustAmount
          entrustAmountDisplay
          entrustPrice
          entrustBalance
          businessPrice
          businessAmount
          businessAmountDisplay
          businessBalance
          registerAmount
          entrustStatus
          withdrawAmount
          tradeDate
          currTime
          currDate
          lastUpdateDatetime
          showTips
          orderDetail {
            title
            value
            toShow
          }
        }
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 个人
 * 账户密码 => 请求 code_type = get_lpoa_phone_mask 获取脱敏手机号 GET_LPOA_PHONE_MASK_ERR = 33142
 * 发送短信验证码 => 请求 code_type = send_lpoa_login_sms 获取短信验证码 SEND_LPOA_LOGIN_SMS_ERR = 35114
 * 验证短信验证码 => code_type = check_lpoa_login_sms 验证短信验证码
 */
export const tradLoginManager = gql`
  mutation($params: ParamGhLoginInput!) {
    ghLogin(param: $params) {
      ok
      data {
        sessionId
        ghUserid
        loginIp
        clientId
        expireTime
      }
      reason {
        err
        desc
      }
    }
  }
`
// 延长session
export const extendSession = gql`
  mutation {
    extendSession {
      ok
      data {
        expireTime
      }
      reason {
        err
        desc
      }
    }
  }
`
// 新获取验证码 加登录 20210121
export const sendCodeF = gql`
  mutation ghLogin($params: ParamGhLoginInput!) {
    ghLogin(param: $params) {
      ok
      data {
        sessionId
        ghUserid
        loginIp
        clientId
        expireTime
      }
      reason {
        err
        desc
      }
    }
  }
`
// 获取区号
export const getAreaCodes = gql`
  mutation getAreaCodes($params: ParamGetAreaCodesInput!) {
    getAreaCodes(param: $params) {
      data {
        default {
          cnName
          areaCode
        }
        options {
          cnName
          areaCode
        }
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`

// 改单
export const ModifyOrder = gql`
  mutation ModifyOrder($params: ParamModifyOrderInput!) {
    modifyOrder(param: $params) {
      ok
      data {
        orderNo
        entrustStatus
        attendtion
      }
      reason {
        err
        desc
      }
    }
  }
`

// 查询港和美股行情
export const quotHKAndUS = gql`
  query(
    $filterHk: FilterStkQuotVOInput!
    $filterUs: FilterStkQuotVOInput!
    $filterA: FilterStkQuotVOInput!
  ) {
    quotHongkong: quotHk(filter: $filterHk) {
      code
      result
      message
    }
    quotAmer: quotUs(filter: $filterUs) {
      code
      result
      message
    }
    quotA: quotA(filter: $filterA) {
      code
      result
      message
    }
  }
`

export const quotAll = gql`
  query(
    $filterHk: FilterStkQuotVOInput!
    $filterUs: FilterStkQuotVOInput!
    $filterA: FilterStkQuotVOInput!
  ) {
    quotHK: quotHk(filter: $filterHk) {
      code
      result
      message
    }
    quotUS: quotUs(filter: $filterUs) {
      code
      result
      message
    }
    quotA: quotA(filter: $filterA) {
      code
      result
      message
    }
  }
`
// 修改交易密码
export const UpdateClientPassword = gql`
  mutation UpdateClientPassword($params: ParamUpdateClientPasswordInput!) {
    updateClientPassword(param: $params) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
// 获取本地码表 stkUpdateStockList
export const stkUpdateStockALL = gql`
  query(
    $filterHk: FilterStkVersionVOInput!
    $filterUs: FilterStkVersionVOInput!
    $filterA: FilterStkVersionVOInput!
  ) {
    stkHk: stkUpdateStockList(filter: $filterHk) {
      code
      result
      message
    }
    stkUs: stkUpdateStockList(filter: $filterUs) {
      code
      result
      message
    }
    stkA: stkUpdateStockList(filter: $filterA) {
      code
      result
      message
    }
  }
`
// 获取历史成交订单
export const hisDeliverList = gql`
  query hisDeliver($filter: FilterGetHisDiliverInput!) {
    HisDeliver(filter: $filter) {
      data {
        currentPage
        totalCount
        rows {
          stockCode
          stockName
          exchangeType
          entrustBs
          businessPrice
          businessAmount
          businessBalance
          tradeDate
          businessTime
          transactionFee
          transactionLevy
          commission
          stampDuty
          payFee
          platformUsageFee
          fareTotal
          supervisoryCommissionFee
          transactionActivityFee
          frcLevy
          tradeDetailed {
            title
            value
            toShow
          }
          tradeDetail {
            tradeNo
            businessAmount
            businessPrice
            businessBalance
            businessDate
            businessTime
          }
        }
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`
// 获取我的结单列表
export const statementsPageList = gql`
  query statementsPageList($filter: FilterStatementsPageListInput!) {
    StatementsPageList(filter: $filter) {
      ok
      reason {
        err
        desc
      }
      data {
        totalCount
        currentPage
        email
        rows {
          dateInt
          dateStr
          year
          month
          day
          clientId
          statementId
          statementType
          statementLink
        }
      }
    }
  }
`
// 发送到我的邮箱
export const sendEmail = gql`
  mutation SendStatement($params: ParamSendStatementInput!) {
    sendStatement(param: $params) {
      ok
      reason {
        err
        desc
      }
      data {
        attention
      }
    }
  }
`
// 公告
export const publishedNotices = gql`
  query publishedNotices($filter: FilterpublishedNoticesInput!) {
    publishedNotices(filter: $filter) {
      code
      message
      result {
        id
        title
        content
        clientLocations
        userType
      }
    }
  }
`
// 用户信息
export const userData = gql`
  query userData {
    userData: userData {
      uid
      phone
      areaCode
      isDelayQuot
      isShowAQuot
      hkQuotPackage
      usQuotPackage
      aQuotPackage
      isOpenAccount
      isPiVerify
      isAllowSearchAQuot
      clientList {
        clientId
        clientType
        areaCode
        phone
        tradeVaild
        need2Fa
        is_hosting
        hostingStatus
        isConfirmStructured
        isPiVerify
        isRiskAssess
        riskAssessLevel
        isBuyChinaConnect
        piApproveState
        piRejectDesc
        tradeDerivativeResult
        isAllowDerivativesTrade
        clientName
        riskStatus
        piState
        riskState
        institutionPaterClient
        nameCn
        nameEn
        fundAccountType
        hasSubClient
      }
      lastOver90
    }
  }
`
// 行情协议
export const commonData = gql`
  query commonData {
    serverTime(timeZone: "Asia/Shanghai") {
      timeStamp
      timeZone
      timeStr
      timeIsDst
    }
    quotPackagePrompt {
      ok
      data {
        expireTip
        promptTip
        openTip
        webUrl
      }
      reason {
        err
        desc
      }
    }
    userData {
      uid
      phone
      areaCode
      isDelayQuot
      isShowAQuot
      hkQuotPackage
      usQuotPackage
      aQuotPackage
      isOpenAccount
      isPiVerify
      isAllowSearchAQuot
      clientList {
        clientId
        clientType
        areaCode
        phone
        tradeVaild
        need2Fa
        is_hosting
        isConfirmStructured
        isPiVerify
        isRiskAssess
        riskAssessLevel
        isBuyChinaConnect
        piApproveState
        piRejectDesc
        tradeDerivativeResult
        isAllowDerivativesTrade
        clientName
        riskStatus
        hasSubClient
      }
    }
  }
`

export const recordPromptAction = gql`
  mutation recordPromptAction($param: RecordPromptActionInput!) {
    recordPromptAction(param: $param) {
      ok
      data
      reason {
        desc
      }
    }
  }
`
export const sendPhoneSms = gql`
  mutation sendPhoneSms($param: ParamSendPhoneSmsInput!) {
    sendPhoneSms(param: $param) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
export const getSmsVaild = gql`
  mutation getSmsVaild($param: ParamGetSmsVaildInput!) {
    getSmsVaild(param: $param) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
// 交易密码
export const verifyTradePasswd = gql`
  mutation verifyTradePasswd($params: VerifyTradePasswdInput!) {
    verifyTradePasswd(param: $params) {
      ok
      data
      reason {
        err
        desc
      }
    }
  }
`

// 首页查询用户信息
export const homeAccData = gql`
  query homeAccData(
    $stockPage: FilterGetStockPosPageListInput!
    $fundAcc: FilterFundAccMarketListInput!
  ) {
    stockPosPageList: StockPosPageList(filter: $stockPage) {
      data {
        currentPage
        rows {
          exchangeType
          stockCode
          clientId
          fundAccount
          moneyType
          stockType
          beginAmount
          currentAmount
          currentAmountDisplay
          uncomeBuyAmount
          uncomeSellAmount
          uncomeBuyAmountT1
          uncomeSellAmountT1
          uncomeBuyAmountT2
          uncomeSellAmountT2
          uncomeBuyAmountTn
          uncomeSellAmountTn
          frozenAmount
          sumBuyAmount
          sumBuyBalance
          sumSellAmount
          sumSellBalance
          costPrice
          costPriceDisplay
          entityAmount
          incomeBalance
          incomeBalanceDisplay
          enableAmount
          enableAmountDisplay
          stockName
          marketValue
          marketValueDisplay
          marginRatio
          marginValue
          realBuyAmount
          realSellAmount
          stockNameBig5
          stockNameGb
          closePrice
          stkcodeStatus
          suspendDays
          greyFlag
          greyMarketDate
          realBuyBalance
          realSellBalance
          currentPriceDisplay
          incomePercentDisplay
        }
        totalCount
      }
      ok
      reason {
        err
        desc
      }
    }
    fundAccMarketList: FundAccMarketList(filter: $fundAcc) {
      data {
        fundAccount
        assetProp
        moneyType
        exchangeType
        enableBalance
        enableBalanceDisplay
        marketValue
        marketValueDisplay
        marginValue
        currentBalance
        uncomeBuyBalance
        uncomeSellBalance
        frozenBalance
        frozenBalanceDisplay
        unfrozenBalance
        checkBalance
        tradeLimit
        creditLimit
        fineIntegralBalance
        interestIntegralBalance
        realSellBalance
        realBuyBalance
        cashOnHold
        settdayBalance
        t1dayBalance
        ledgerBalance
        marginCall
        multiCurrencyBuyPower
        tradedayBalance
        fetchBalance
        fetchBalanceDisplay
        netInterestBalance
        transferBalance
        settledBalance
        unsettBalance
        asset
        assetDisplay
        fundTransferBalance
        ipoBalance
        creditRatio
        ipoDeductBalance
        positionIncome
        dailyIncome
        cashBuyPower
        totalBuyPower
      }
      ok
      reason {
        err
        desc
      }
    }
    userData {
      uid
      phone
      areaCode
      isDelayQuot
      isShowAQuot
      hkQuotPackage
      usQuotPackage
      aQuotPackage
      isOpenAccount
      isPiVerify
      isAllowSearchAQuot
      clientList {
        clientId
        clientType
        areaCode
        phone
        tradeVaild
        need2Fa
        is_hosting
        isConfirmStructured
        isPiVerify
        isRiskAssess
        riskAssessLevel
        isBuyChinaConnect
        piApproveState
        piRejectDesc
        tradeDerivativeResult
        isAllowDerivativesTrade
        clientName
        riskStatus
        hasSubClient
      }
    }
  }
`
// 更新交易账户密码提醒时间
export const updatePwdExpiryDate = gql`
  mutation updatePwdExpiryDate($param: UpdatePwdExpiryDateInput!) {
    updatePwdExpiryDate(param: $param) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
// 延长交易时间
export const extendTimeClient = gql`
  mutation extendTimeClient($params: ExtendTimeClientInput) {
    extendTimeClient(param: $params) {
      ok
      data {
        clientId
        tradeExpireTime
      }
      reason {
        err
        desc
      }
    }
  }
`
// 校验身份证 checkIdCard
export const checkIdCard = gql`
  query checkIdCard($filter: FilterCheckIdCardInput!) {
    checkIdCard(filter: $filter) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
// 校验邮箱 checkUserInfo
export const checkUserInfo = gql`
  query checkUserInfo($filter: FilterCheckUserInfoInput!) {
    checkUserInfo(filter: $filter) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
// 重置密码 resetTradePassword
export const resetTradePassword = gql`
  mutation resetTradePassword($param: ParamResetTradePasswordInput!) {
    resetTradePassword(param: $param) {
      ok
      data {
        resBool
      }
      reason {
        err
        desc
      }
    }
  }
`
