
import { useMutation } from '@vue/apollo-composable'
import { PropType, defineComponent, nextTick, onMounted, reactive } from 'vue'
import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import Desensitization, { DesensitizationFormat } from '@/utils/desensitization'
import { ElMessage } from 'element-plus'
import { VerifyData } from '@/hooks/useVerifyPwd'

type VerifyState = {
  areaCode: string
  phone: string
  defaultPhone: string
  smsCode: string
  smsBtnStatus: boolean
  countNum: number
}

export default defineComponent({
  name: 'Verify2FADialog',
  props: {
    verifyData: {
      type: Object as PropType<VerifyData>,
      default: () => ({})
    },
    verify2faDialog: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:verify2faDialog', 'confirm'],
  setup (props, { emit }) {
    const state: VerifyState = reactive({
      areaCode: '',
      phone: '',
      defaultPhone: '',
      smsCode: '',
      smsBtnStatus: false,
      countNum: 60
    })

    const { mutate: sendCode, onDone: sendCodeDone, loading: sendCodeLoading } = useMutation(gql.sendPhoneSms)
    const { mutate: verifyCode, onDone: verifyDone } = useMutation(gql.getSmsVaild)

    const sendSmsHandle = () => {
      const params = {
        param: {
          areaCode: state.areaCode,
          phone: state.defaultPhone,
          optType: 'normal',
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        }
      }
      state.smsBtnStatus = true
      sendCode(params)
    }

    sendCodeDone((res) => {
      const { sendPhoneSms } = res?.data
      if (sendPhoneSms.ok && sendPhoneSms.data?.resBool) {
        const timer = setInterval(() => {
          if (state.countNum) {
            state.countNum--
          } else {
            clearInterval(timer)
            state.smsBtnStatus = false
            state.countNum = 60
          }
        }, 1000)
      } else {
        state.smsBtnStatus = false
        ElMessage.error(sendPhoneSms.reason.desc)
      }
    })
    const close = () => {
      state.areaCode = ''
      state.phone = ''
      state.defaultPhone = ''
      state.smsCode = ''
      state.smsBtnStatus = false
      state.countNum = 60
      emit('update:verify2faDialog', false)
    }

    const submit = () => {
      const params = {
        param: {
          areaCode: state.areaCode,
          phone: state.defaultPhone,
          msgCode: state.smsCode,
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
          authType: 3
        }
      }
      verifyCode(params)
    }

    verifyDone((res) => {
      debugger
      const { getSmsVaild } = res?.data
      if (getSmsVaild.ok && getSmsVaild.data.resBool) {
        close()
        emit('confirm')
      } else {
        ElMessage.error(getSmsVaild.reason.desc)
      }
    })

    onMounted(() => {
      const { areaCode, phone } = storage.rcGetItem(StorageType.local, 'userData')
      state.areaCode = areaCode
      state.defaultPhone = phone
      state.phone = Desensitization(phone, { format: DesensitizationFormat.telPhone })
    })

    return {
      state,
      close,
      sendSmsHandle,
      submit,
      sendCodeLoading
    }
  }
})
