import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Notice = _resolveComponent("Notice")!
  const _component_TagsView = _resolveComponent("TagsView")!
  const _component_AppMain = _resolveComponent("AppMain")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_RightPanel = _resolveComponent("RightPanel")!
  const _component_Subscribe = _resolveComponent("Subscribe")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_VerifyPwd = _resolveComponent("VerifyPwd")!
  const _component_Verify2FADialog = _resolveComponent("Verify2FADialog")!
  const _component_revise_pwd = _resolveComponent("revise-pwd")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.classObj, "app-wrapper"])
  }, [
    (_ctx.classObj.mobile && _ctx.sidebar.opened)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "drawer-bg",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Sidebar, { class: "sidebar-container" }),
    _createElementVNode("div", {
      class: _normalizeClass([{ hasTagsView: _ctx.showTagsView, hasNotice: _ctx.showMarquee }, "main-container"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'fixed-header': _ctx.fixedHeader })
      }, [
        _createVNode(_component_Navbar, { onRevisePwd: _ctx.revisePwd }, null, 8, ["onRevisePwd"]),
        _createVNode(_component_Notice, {
          showMarquee: _ctx.showMarquee,
          onCloseNotice: _ctx.closeNotice
        }, null, 8, ["showMarquee", "onCloseNotice"]),
        (_ctx.showTagsView)
          ? (_openBlock(), _createBlock(_component_TagsView, { key: 0 }))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_AppMain),
      (_ctx.showSettings)
        ? (_openBlock(), _createBlock(_component_RightPanel, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_Settings)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_Dialog, {
      notice: _ctx.subList,
      dialogVisible: _ctx.quotStatus,
      "onUpdate:dialogVisible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.quotStatus) = $event)),
      onDialogClose: _ctx.subDialogClose,
      onDialogConfirm: _ctx.dialogConfirm,
      hasCancel: ""
    }, {
      content: _withCtx(() => [
        _createVNode(_component_Subscribe, {
          professional: _ctx.professional,
          name: _ctx.name,
          fundAccount: _ctx.fundAccount,
          onChangeRadio: _ctx.changeRadio
        }, null, 8, ["professional", "name", "fundAccount", "onChangeRadio"])
      ]),
      _: 1
    }, 8, ["notice", "dialogVisible", "onDialogClose", "onDialogConfirm"]),
    _createVNode(_component_VerifyPwd, {
      verifyPwdDialog: _ctx.verifyData.verifyDialog,
      "onUpdate:verifyPwdDialog": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.verifyData.verifyDialog) = $event)),
      submitLoading: _ctx.submitLoading,
      onSubmitCallback: _ctx.submitCallback
    }, null, 8, ["verifyPwdDialog", "submitLoading", "onSubmitCallback"]),
    (_ctx.verifyData.verify2FADialog)
      ? (_openBlock(), _createBlock(_component_Verify2FADialog, {
          key: 1,
          verify2faDialog: _ctx.verifyData.verify2FADialog,
          "onUpdate:verify2faDialog": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.verifyData.verify2FADialog) = $event)),
          onConfirm: _ctx.towFAConfirm
        }, null, 8, ["verify2faDialog", "onConfirm"]))
      : _createCommentVNode("", true),
    _createVNode(_component_revise_pwd, {
      visible: _ctx.pwdFlag,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pwdFlag) = $event))
    }, null, 8, ["visible"])
  ], 2))
}